@use "variables" as *;

.header-logo {
  align-items: center;
  display: flex;
  margin: 0 15px 0 0;

  &__extension {
    align-self: center;
    border-left: 1px solid $accent-default;
    display: inline-block;
    margin: 0 0 0 8px;
    padding: 2px 0 2px 8px;
  }
}
