@use "variables" as *;
// This css class is used in adapters.
.step-statement {
  background: $olive-subtle;
  border: 1px solid $utility-border-opacity-20;
  border-radius: $border-radius-s;
  color: $olive-fg-emphasis;
  font-size: 12px;
  font-weight: 600;
  margin-right: 3px;
  padding: 3px 5px 4px;
  text-transform: uppercase;

  &_condition-variant {
    background-color: $gold-tint;
    color: $gold-fg-emphasis;
  }

  &_error-handling-variant {
    background-color: $orange-tint;
    color: $orange-fg-emphasis;
  }

  &_loop-variant {
    background-color: $blue-tint;
    color: $blue-fg-emphasis;
  }

  &_return-variant {
    background-color: $teal-tint;
    color: $teal-fg-emphasis;
  }

  &_stop-variant {
    background-color: $red-tint;
    color: $red-fg-emphasis;
  }

  &_separated {
    $separator-width: 1px;
    $separator-padding-top: 2px;

    margin-right: 11px + $separator-width;
    position: relative;

    &::after {
      background: $border-default;
      border-radius: 0.5px;
      content: "";
      height: calc(100% - #{2 * $separator-padding-top});
      position: absolute;
      right: -7px - $separator-width;
      top: $separator-padding-top;
      width: $separator-width;
    }
  }
}
