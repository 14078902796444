@use "variables" as *;
@use "mixins" as *;

.dashboard-aside {
  background: $surface-default;
  border: 1px solid $border-subtle;
  border-radius: $border-radius-l;
  margin-bottom: $page-aside-margin;
  padding: 0;

  @include device(desktop-small) {
    margin-bottom: $page-aside-compact-margin;
  }

  &__title {
    background: $surface-elevated;
    border-bottom: 1px solid $border-subtle;
    border-top-left-radius: $border-radius-l;
    border-top-right-radius: $border-radius-l;
    font-size: 20px;
    font-weight: 700;
    margin: 0;
    padding: 32px 0 50px;
    text-align: center;

    &_with-note {
      padding-top: 22px;
    }
  }

  &__title-note {
    color: $text-secondary;
    font-size: 14px;
    font-weight: 400;
  }

  &__summary {
    border-bottom: 1px solid $border-subtle;
    padding: 15px 30px 32px 32px;
    position: relative;

    &_static {
      position: static;
    }
  }

  &__subtitle {
    background: $surface-elevated;
    border: 1px solid $border-subtle;
    border-radius: 15px;
    box-shadow: $card-resting-shadow;
    color: $text-secondary;
    font-size: 12px;
    left: 0;
    margin: -17px auto 0;
    padding: 8px 10px;
    text-align: center;
    width: 200px;
  }

  &__counters {
    display: flex;
    justify-content: space-between;
  }

  &__content {
    padding: 16px;
  }

  &__content-title {
    border-bottom: 1px solid $neutral-border;
    margin: 16px 16px 0;
    padding-bottom: 7px;
  }

  &__content-title-amount {
    font-size: 18px;
    margin: 0 2px 0 0;
  }

  &__content-item {
    margin-bottom: 8px;

    &:last-child {
      margin: 0;
    }
  }

  &__list {
    list-style: none;
    margin: 0 16px 16px;
    padding: 0;
  }

  &__footer {
    background: $neutral-tint;
    border-radius: 0 0 12px 12px;
    border-top: 1px solid $border-subtle;
    font-size: 12px;
    line-height: 16px;
    padding: 20px 30px;
  }

  &__footer-link {
    font-weight: 600;
  }
}
