@use "variables" as *;
@use "media-queries" as *;

@mixin container {
  box-sizing: content-box;
  padding-left: $container-padding-left;
  padding-right: $container-padding-right;

  @include device(tablet) {
    padding-left: 16px;
    padding-right: 16px;
  }

  .w-page-global_size_large & {
    max-width: 1896px;
  }

  .w-page-global_size_medium & {
    max-width: 1512px;
  }

  .w-page-global_size_small & {
    max-width: 1128px;
  }

  .guest-page & {
    margin-left: $container-margin-left;
    margin-right: $container-margin-right;
    max-width: $guest-container-default-max-width;
    padding-left: $container-guest-padding-left;
    padding-right: $container-guest-padding-right;
  }
}
