@use "variables" as *;

.w-dnd {
  /* stylelint-disable declaration-no-important */
  &__active {
    user-select: none !important;

    *,
    *::after,
    *::before {
      cursor: image-url("closedhand.cur"), move !important;
    }
  }

  &__handle {
    cursor: move !important;
  }

  &__dragging {
    opacity: 0.5;
    pointer-events: none;
    transition: opacity 0.5s ease;
  }

  &__dragging-clone {
    box-shadow: $dragged-shadow;
    left: 0;
    opacity: 0.8;
    position: fixed !important;
    top: 0;
    transform: translateZ(0);
    z-index: $dnd-z-index;
  }
  /* stylelint-enable */
}
