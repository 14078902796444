/* Prevents scrollbars on the `CdkDragPreview` element that may appear due to the `[popover]` attribute.
 * User Agent Stylesheet applies:
 * [popover]:popover-open:not(dialog) {
 *   overlay: auto !important;
 * }
 * So any overflow brings to scrollbars.
 */
.cdk-drag-preview[popover] {
  overflow: hidden;
}
