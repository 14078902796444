@use "shared/mixins/map-get-strict" as *;
@use "shared/mixins/wrap-with-root" as *;

@mixin base($settings) {
  @include wrap-with-root {
    @include _tab-vars(map_get_strict($settings, "tab"));
    @include _tabs-vars(map_get_strict($settings, "tabs"));
    @include _counter-vars(map_get_strict($settings, "counter"));
    @include _section-vars(map_get_strict($settings, "section"));
    @include _compact-header-vars(map_get_strict($settings, "compact-header"));
    @include _last-vars(map_get_strict($settings, "last"));
  }
}

@mixin _tab-vars($settings) {
  --vertical-tab-navigation-tab-padding: #{map_get_strict($settings, "padding")};
  --vertical-tab-navigation-tab-active-color: #{map_get_strict($settings, "active-color")};
  --vertical-tab-navigation-tab-active-background: #{map_get_strict($settings, "active-background")};
  --vertical-tab-navigation-tab-inactive-color: #{map_get_strict($settings, "inactive-color")};
  --vertical-tab-navigation-tab-border-radius: #{map_get_strict($settings, "border-radius")};
  --vertical-tab-navigation-tab-hover-background: #{map_get_strict($settings, "hover-background")};

  --vertical-tab-navigation-tab-inactive-hover-content-color: #{map_get_strict(
      $settings,
      "inactive-hover-content-color"
    )};
  --vertical-tab-navigation-tab-inactive-hover-border-color: #{map_get_strict($settings, "inactive-hover-border-color")};
  --vertical-tab-navigation-tab-inactive-hover-shadow: #{map_get_strict($settings, "inactive-hover-shadow")};

  --vertical-tab-navigation-subtle-tab-active-color: #{map_get_strict($settings, "subtle-active-color")};
  --vertical-tab-navigation-subtle-tab-active-background: #{map_get_strict($settings, "subtle-active-background")};
  --vertical-tab-navigation-subtle-indent-tab-active-background: map_get_strict(
    $settings,
    "subtle-indent-active-background"
  );

  --vertical-tab-navigation-indent-tab-active-background: #{map_get_strict($settings, "indent-active-background")};
  --vertical-tab-navigation-indent-tab-background: #{map_get_strict($settings, "indent-background")};
  --vertical-tab-navigation-indent-tab-inactive-hover-background: map_get_strict(
    $settings,
    "indent-inactive-hover-background"
  );
}

@mixin _tabs-vars($settings) {
  --vertical-tab-navigation-tabs-background: #{map_get_strict($settings, "background")};
  --vertical-tab-navigation-tabs-border-color: #{map_get_strict($settings, "border-color")};
  --vertical-tab-navigation-tabs-border-radius: #{map_get_strict($settings, "border-radius")};
}

@mixin _counter-vars($settings) {
  --vertical-tab-navigation-counter-background: #{map_get_strict($settings, "background")};
  --vertical-tab-navigation-counter-color: #{map_get_strict($settings, "color")};
  --vertical-tab-navigation-counter-active-background: #{map_get_strict($settings, "active-background")};
  --vertical-tab-navigation-subtle-counter-active-background: #{map_get_strict($settings, "subtle-active-background")};
}

@mixin _section-vars($settings) {
  --vertical-tab-navigation-section-icon-color: #{map_get_strict($settings, "icon-color")};
  --vertical-tab-navigation-section-title-color: #{map_get_strict($settings, "title-color")};
  --vertical-tab-navigation-indent-section-title-background: #{map_get_strict($settings, "indent-background")};
}

@mixin _compact-header-vars($settings) {
  --vertical-tab-navigation-compact-header-expand-color: #{map_get_strict($settings, "expand-color")};
  --vertical-tab-navigation-compact-header-section-color: #{map_get_strict($settings, "section-color")};
  --vertical-tab-navigation-compact-header-tab-color: #{map_get_strict($settings, "tab-color")};
}

@mixin _last-vars($settings) {
  --vertical-tab-navigation-indent-last-active-background: #{map_get_strict($settings, "indent-last-active-background")};
  --vertical-tab-navigation-indent-last-background: #{map_get_strict($settings, "indent-last-background")};

  --vertical-tab-navigation-subtle-indent-last-active-background: map_get_strict(
    $settings,
    "subtle-indent-last-active-background"
  );
}
