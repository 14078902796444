@use "variables" as *;

.recipe-step-dnd {
  &__clone {
    /* stylelint-disable declaration-no-important */
    .recipe-step__header {
      border-color: $accent-border-emphasis !important;
      box-shadow: $dragged-shadow !important;
      z-index: 0 !important;
    }

    .recipe-step__actions {
      display: none !important;
    }
    /* stylelint-enable */
  }

  &__steps-count {
    background: $accent-default;
    border-radius: 2px;
    color: $fg-onAccent;
    font-size: 12px;
    font-weight: 700;
    left: 50%;
    padding: 2px 5px;
    position: absolute;
    top: 0;
    transform: translate(-50%, -55%);
    z-index: 1;
  }

  &__shadow {
    background: $surface-default;
    border: 1px solid $accent-border;
    border-radius: 2px;
    box-shadow: $dragged-shadow;
    inset: 0;
    position: absolute;
  }
}
