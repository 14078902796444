@use "variables" as *;
@use "mixins" as *;

.w-main-header {
  inset: 0 0 auto;
  position: fixed;
  z-index: $header-z-index;

  @include without-nav {
    display: none !important; /* stylelint-disable-line declaration-no-important */
  }

  @include device(compact) {
    .w-page-global_pushed & {
      /* stylelint-disable selector-max-compound-selectors */
      transform: translate(-$guest-header-menu-push-tablet, 0);

      .header::after {
        height: 110vh;
        opacity: 0.5;
      }
    }
  }

  @include device(phone) {
    .w-page-global_pushed & {
      transform: translate(-$guest-header-menu-push-phone, 0);
    }
  }
}
