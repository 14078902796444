@use "variables" as *;
@use "mixins" as *;

.recipe-step-details-title {
  $vertical-padding: 14px;
  $inner-spacing: 8px;

  align-items: flex-start;
  display: flex;

  &__main {
    align-items: center;
    cursor: pointer;
    display: flex;
    flex: 1;
    padding: $vertical-padding 0 $vertical-padding $panel-padding-horizontal;

    &_static {
      cursor: default;
      padding-right: $panel-padding-horizontal;
    }

    @include device(phablet) {
      padding: $vertical-padding $panel-padding-horizontal-mobile;
    }
  }

  &__content {
    padding-left: $inner-spacing;
  }

  &__icon {
    align-self: stretch;
    flex: 0 0 auto;
  }

  &__actions {
    align-items: center;
    display: flex;
    margin: $vertical-padding $panel-padding-horizontal $vertical-padding 0;
    min-height: $recipe-step-icon-size;
    padding-left: $inner-spacing;

    @include device(phablet) {
      margin: $vertical-padding $panel-padding-horizontal-mobile $vertical-padding 0;
    }
  }

  &__action {
    margin-left: 4px;

    &:first-child {
      margin-left: 0;
    }
  }

  &__action + &__close {
    margin-left: 16px;

    &::after {
      border-right: 1px solid $border-subtle;
      content: "";
      height: 20px;
      left: -8px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__close {
    @include reset-button;

    color: $icon-default;
    cursor: pointer;
    font-size: 0;
    font-weight: 700;
    position: relative;
  }
}
