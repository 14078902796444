@use "variables" as *;
@use "mixins" as *;

.manifest {
  display: flex;

  @include device(tablet) {
    display: block;
  }

  &__assets {
    flex: 1;
  }

  &__counters {
    display: block;
    margin-left: $page-aside-margin;
    width: 360px;

    &_small {
      width: 240px;
    }

    @include device(tablet) {
      margin: $page-aside-compact-margin 0 0;
      width: 100%;
    }
  }

  &__empty {
    display: block;
    margin-top: 8px;

    &:only-child {
      margin-top: 0;
    }
  }
}
