@use "variables" as *;
@use "mixins" as *;
@use "shared/mixins/select" as *;

.tree-select {
  @include select;
}

.tree-select_theme_fluid {
  &.tree-select {
    @include select-fluid-theme(tree-select);

    .tree-select__value_placeholder:last-child {
      padding-right: 0;
    }

    .tree-select__wrapper:last-child .tree-select__value_placeholder:last-child {
      padding-right: 7px;
    }
  }

  &.tree-select__dropdown {
    @include select-dropdown-fluid-theme;

    &_with-search {
      .tree-select-nodes_root {
        margin-top: 0;
      }
    }
  }

  .tree-select-nodes {
    border-top: none;

    &_root {
      margin: 8px 0;
    }

    &__branch {
      border-top: none;
      position: relative;

      &:not(:last-child) > .tree-select-nodes__ruler {
        display: block;
      }
    }

    &__node {
      padding: 0 8px;

      &_selected-path {
        .tree-select-nodes__node-expander {
          color: $icon-default;
        }
      }

      &_selected {
        color: $accent-fg-emphasis;
        font-weight: 600;

        .tree-select-nodes__node-expander {
          color: $accent-fg-emphasis;
        }
      }

      &_active {
        .tree-select-nodes__node-content {
          background: $surface-inverse-opacity-5;
          border-radius: $border-radius-s;
        }
      }
    }

    &__node-content {
      padding-right: 16px;
    }

    &__node-expander {
      color: $icon-default;

      &:hover {
        color: $accent-emphasis;
      }

      &_with-ruler::before {
        border-bottom: 1px solid $border-default;
        border-left: 1px solid $border-default;
        content: "";
        height: 50%;
        left: 0;
        position: absolute;
        top: 0;
        width: 7px;
      }
    }

    &__empty-msg {
      padding-left: 14px;
    }
  }
}

.tree-select_alert-type_error {
  &.tree-select {
    @include select-error-styles(tree-select);
  }

  &.tree-select__dropdown {
    @include select-dropdown-error-styles;
  }
}

.tree-select_alert-type_warning {
  &.tree-select {
    @include select-warning-styles(tree-select);
  }

  &.tree-select__dropdown {
    @include select-dropdown-warning-styles;
  }
}
