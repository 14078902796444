@use "theming" as *;
@use "shared/lcap/theming" as *;

.theming-context {
  &_app {
    @include app-shared-theming;
  }

  &_lcap {
    color-scheme: normal;

    @include lcap-shared-theming;
    @include lcap-editor-theming;
  }
}
