@use "variables" as *;

.multiselection-area {
  background: $accent-muted-opacity-15;
  border: 1px solid transparent;
  border-radius: 6px;
  opacity: 0;
  position: absolute;
  transition:
    opacity ease 0.3s,
    border-color 0.3s ease;
  z-index: 100;

  &_type_copy:not(.recipe-step-draggable) {
    background-color: $accent-muted-opacity-10;
    border-color: $accent-border;
    border-style: dashed;
    z-index: 0;
  }

  &_active,
  &:hover {
    border-color: $accent-border-opacity-50;
  }

  &_passive,
  &.recipe-step-draggable {
    &:hover {
      border-color: transparent;
    }
  }
}
