@use "variables" as *;

.chart-empty {
  background: $surface-opacity-50;
  color: $text-secondary;
  font-size: 14px;
  padding: 10px;
  position: absolute;
  text-align: center;
  text-anchor: middle;
  transform: translate(-50%, -50%);
  width: calc(100% - 32px);
}
