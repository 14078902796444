$primary-font-family: var(--primary-font-family);
$default-font-size: var(--default-font-size);
$h1-font-size: var(--h1-font-size);
$h2-font-size: var(--h2-font-size);
$h3-font-size: var(--h3-font-size);
$h4-font-size: var(--h4-font-size);
$h5-font-size: var(--h5-font-size);
$h6-font-size: var(--h6-font-size);

$source-code-font-family: "Source Code Pro", Menlo, Monaco, Consolas, "Courier New", monospace;
$kalam-font-family: "Kalam", cursive;
$default-line-height: 1.4286;
