@use "variables" as *;
@use "mixins" as *;

.w-page-header {
  @include container;
  $block: &;

  padding-bottom: 32px;
  padding-top: 32px;
  position: relative;

  &_bordered {
    &::after {
      bottom: 0;
      box-shadow: 0 1px 0 $border-default;
      content: "";
      display: block;
      height: 1px;
      left: $container-padding-left;
      position: absolute;
      right: $container-padding-right;

      .guest-page & {
        left: $container-guest-padding-left;
        right: $container-guest-padding-right;
      }
    }
  }

  &__content {
    display: grid;
    grid-template:
      "icon breadcrumbs breadcrumbs" auto
      "icon main aside" auto
      "icon footer footer-aside" auto
      "notifications notifications notifications" auto /
      auto 1fr auto;

    @include device(tablet) {
      grid-template:
        "icon breadcrumbs" auto
        "icon main" auto
        "icon footer" auto
        "aside aside" auto
        "footer-aside footer-aside" auto
        "notifications notifications" auto /
        auto 1fr;
    }
  }

  &__back {
    display: block;
    grid-area: breadcrumbs;
    margin-bottom: 8px;
  }

  &__notifications {
    grid-area: notifications;
    margin: 16px 0 -16px;
  }

  &__notification {
    display: block;
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__breadcrumbs {
    display: block;
    grid-area: breadcrumbs;
    margin-bottom: 8px;
  }

  &__main {
    align-self: center;
    grid-area: main;

    &_no-overflow {
      overflow: hidden;
    }
  }

  &__aside {
    align-self: end;
    grid-area: aside;
    margin-left: 32px;
    text-align: right;

    @include device(tablet) {
      margin-left: 0;
      margin-top: 16px;
      text-align: left;
      width: 100%;
    }
  }

  &__aside-row {
    display: flex;
    gap: 12px;
  }

  &__icon-wrapper {
    align-items: center;
    align-self: stretch;
    background: $surface-default;
    border-radius: $border-radius-m;
    display: flex;
    grid-area: icon;
    // fix for https://bugs.webkit.org/show_bug.cgi?id=26559 in safari
    // children can't inherit parent's height if min-height is used
    height: 100%;
    justify-content: center;
    margin: 0 16px 0 0;
    min-height: 52px;
    width: 52px;

    &_package {
      background: $surface-alt;
      color: $neutral-fg-emphasis;
    }

    &_bot {
      background: $surface-alt;
      color: $neutral-emphasis;
    }

    &_recipe {
      background-color: $indigo-subtle;
      color: $indigo-fg-emphasis;
    }

    &_api-proxy-endpoint,
    &_api-proxy-group {
      background-color: $blue-subtle;
      color: $blue-fg-emphasis;
    }

    &_api-endpoint,
    &_api-group {
      background-color: $indigo-subtle;
      color: $indigo-fg-emphasis;
    }

    &_data-pipeline {
      background-color: $violet-tint;
      color: $violet-emphasis;
    }

    &_profile {
      background: none;
      min-height: 40px;
      width: 40px;
    }
  }

  &__footer {
    @include break-word;

    grid-area: footer;

    &_no-overflow {
      overflow: hidden;
    }
  }

  &__footer-groups {
    align-items: center;
    display: flex;
    flex-wrap: wrap;

    @include device(tablet) {
      display: block;
    }

    & + & {
      margin-top: 8px;
    }
  }

  &__footer-group {
    @include break-word;

    display: block;
    margin-right: 25px;
    position: relative;

    @include device(tablet) {
      margin: 4px 0 0;

      &::after {
        display: none;
      }
    }

    #{$block}__footer-groups_compact & {
      margin-right: 15px;
    }

    &::after {
      background: $neutral-border;
      content: "";
      height: 16px;
      position: absolute;
      right: -12px;
      top: 50%;
      transform: translateY(-50%);
      width: 1px;

      #{$block}__footer-groups_compact & {
        right: -8px;
      }
    }

    &:last-child {
      margin-right: 0;

      &::after {
        display: none;
      }
    }
  }

  &__footer-aside {
    grid-area: footer-aside;
    margin-left: 32px;
    text-align: right;

    @include device(tablet) {
      margin-left: 0;
      text-align: left;
    }
  }

  &__icon {
    border-radius: $border-radius-m;
    display: inline-block;
    height: 44px;
    overflow: hidden;
    width: 44px;

    &_full-size {
      height: 100%;
      width: 100%;

      .app-icon {
        height: 100%;
        width: 100%;

        &__icon {
          border-radius: $border-radius-m;
        }
      }
    }

    &_smaller {
      border-radius: $border-radius-xs;
      height: 24px;
      width: 24px;
    }

    &_no-border-radius {
      border-radius: 0;
    }

    &_overflow-visible {
      overflow: visible;
    }
  }

  &__title {
    @include break-word;

    color: $text-primary;
    font-size: $h1-font-size;
    font-weight: 700;
    line-height: 1.3;
    margin: 0;
    padding: 0;
    word-break: break-word;

    &_subpage {
      font-size: $h2-font-size;
    }

    &_small {
      font-size: $h4-font-size;
      font-weight: 600;
      line-height: 1.5;
    }

    &_editable {
      overflow: visible;
    }
  }

  &__title-tag {
    margin-left: 8px;
  }

  &__title-groups {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
  }

  &__title-group {
    display: block;
    margin-right: 12px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__footer-aside,
  &__footer {
    color: $text-primary;
    font-size: 16px;
    margin-top: 8px;

    &:empty {
      display: none;
    }
  }

  &__aside-actions {
    align-items: center;
    display: flex;
    justify-content: flex-end;

    @include device(tablet) {
      justify-content: flex-start;
    }
  }

  &__aside-separator {
    background: $neutral-border;
    height: 36px;
    margin: 0 12px;
    width: 1px;

    &:last-child {
      display: none;
    }

    &_small {
      height: 16px;
      margin: 0 8px;
    }
  }
}
