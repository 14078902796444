@use "variables" as *;

.framed-panel {
  $panel-selector: &;

  align-items: center;
  background: $surface-subtle;
  border: 1px solid $border-subtle;
  border-radius: $border-radius-s;
  color: $text-secondary;
  display: flex;
  flex-direction: row;
  padding: 8px;

  &_icon-top {
    align-items: flex-start;

    #{$panel-selector}__icon {
      margin-top: 5px;
    }
  }

  &__icon {
    height: 12px;
    margin: 0 10px 1px 0;
    width: 12px;

    &_success {
      color: $success-fg;
    }

    &_warning {
      color: $warning-fg;
    }
  }
}
