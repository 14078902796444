@use "variables" as *;
@use "mixins" as *;

$sizes: (
  xsmall: (
    "size": 24px,
    "border-radius": $border-radius-s,
  ),
  small: (
    "size": 28px,
    "border-radius": $border-radius-s,
  ),
  medium: (
    "size": 32px,
    "border-radius": $border-radius-s,
  ),
  large: (
    "size": 44px,
    "border-radius": $border-radius-m,
  ),
  xlarge: (
    "size": 56px,
    "border-radius": $border-radius-m,
  ),
  2xlarge: (
    "size": 68px,
    "border-radius": $border-radius-l,
  ),
);

@mixin icon-sizes {
  @each $name, $value in $sizes {
    $icon-size: map_get($value, "size");
    &_#{$name} {
      height: $icon-size;
      width: $icon-size;
    }

    &_#{$name} &__icon::after {
      height: round($icon-size * $icon-content-scale);
      width: round($icon-size * $icon-content-scale);
    }
  }
}

@mixin icon-border-radius-sizes {
  @each $name, $value in $sizes {
    .app-icon_#{$name} & {
      border-radius: map_get($value, "border-radius");
    }
  }
}

@mixin icon-label-border-radius-sizes {
  @each $name, $value in $sizes {
    .app-icon_#{$name} & {
      border-top-right-radius: map_get($value, "border-radius") - 1;
    }
  }
}

@mixin icon-border-radius-sizes-for-modifier($selectors...) {
  @each $name, $value in $sizes {
    &_#{$name} {
      @each $selector in $selectors {
        &#{$selector} {
          @content (map_get($value, "border-radius"));
        }
      }
    }
  }
}

.app-icon {
  $pill-icon-border-radius: $pill-border-radius 0 0 $pill-border-radius;
  $icon-size: 36px;

  display: inline-block;
  height: $icon-size;
  position: relative;
  width: $icon-size;

  @include icon-sizes;

  &__icon {
    align-items: center;
    border-radius: $border-radius-s;
    display: inline-flex;
    height: 100%;
    justify-content: center;
    position: relative;
    transition: filter 0.2s $w-easy-ease;
    width: 100%;

    &::after {
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: contain;
      content: "";
      height: round($icon-size * $icon-content-scale);
      width: round($icon-size * $icon-content-scale);
    }

    @include icon-border-radius-sizes;

    .app-icon_no-radius & {
      border-radius: 0;
    }
  }

  &_grayed-out {
    .app-icon__icon {
      background-color: $surface-alt;
      filter: #{"grayscale(100%)"};
    }
  }

  &_no-radius {
    @include color-overlay-border-radius(0);
  }

  &_no-border {
    .app-icon__icon {
      /* stylelint-disable-next-line declaration-no-important */
      border: none !important;
    }
  }

  &_faded-out {
    @include color-overlay($surface-opacity-70, $border-radius-s);

    .app-icon_2xlarge & {
      @include color-overlay($surface-opacity-70, $border-radius-l);
    }

    .app-icon_xlarge &,
    .app-icon_large & {
      @include color-overlay($surface-opacity-70, $border-radius-m);
    }
  }

  &_unsupported,
  &_custom {
    .app-icon__icon {
      &::after {
        height: 100%;
        width: 100%;
      }
    }
  }

  @include icon-border-radius-sizes-for-modifier(".app-icon_unsupported", ".app-icon_custom") using ($border-radius) {
    .app-icon__icon {
      &::after {
        border-radius: $border-radius;
      }
    }

    &.app-icon_pill {
      .app-icon__icon {
        &::after {
          border-radius: $pill-icon-border-radius;
        }
      }
    }
  }

  &_pill {
    @include color-overlay-border-radius($pill-icon-border-radius);

    .app-icon__icon {
      &,
      &::after {
        border-radius: $pill-icon-border-radius;
      }
    }
  }

  &__release-type {
    bottom: -6px;
    color: $icon-inverse;
    display: inline-block;
    font-size: 9px;
    font-weight: 600;
    padding: 2px 4px 1px;
    position: absolute;
    right: -3px;

    &::before {
      border: 1px solid $border-inverse;
      border-radius: 4px;
      content: "";
      inset: 0;
      position: absolute;
      transform: skewX(-10deg);
      z-index: 1;
    }

    &::after {
      position: relative;
      text-transform: uppercase;
      z-index: 2;
    }

    &_small {
      font-size: 7px;
      padding: 2px 3px 1px;

      &::before {
        border-radius: 3px;
      }
    }

    &_alpha {
      &::before {
        background: $plum-default;
      }

      &::after {
        content: "Alpha";
      }
    }

    &_beta {
      &::before {
        background: $gold-default;
      }

      &::after {
        content: "Beta";
      }
    }

    &_demo {
      &::before {
        background: $orange-default;
      }

      &::after {
        content: "Demo";
      }
    }

    &_sdk {
      &::before {
        background: $blue-default;
      }

      &::after {
        content: "Private";
      }
    }

    &_partner {
      &::before {
        background: $surface-inverse;
      }

      &::after {
        content: "Partner";
      }
    }
  }

  &__secondary {
    /* stylelint-disable-next-line color-no-hex -- This color should not change with the theme */
    background: #fff;
    border: solid $border-default;
    border-radius: 0 2px;
    border-width: 0 0 1px 1px;
    font-size: 10px;
    line-height: 1;
    padding: 1px 3px;
    position: absolute;
    right: 1px;
    top: 1px;

    @include theme(dark) {
      color: $text-inverse;
    }

    @include theme(light) {
      color: $text-primary;
    }

    @include icon-label-border-radius-sizes;
  }
}
