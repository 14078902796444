@use "variables" as *;
@use "mixins" as *;

.w-card {
  @include card-container;

  align-items: stretch;
  color: inherit;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: $card-min-height;
  padding: 24px;
  position: relative;
  text-align: left;
  width: 100%;

  @include all-link-states {
    color: inherit;
  }

  &_static {
    html:not(.w-dnd__active) & {
      @include all-link-states {
        border-color: transparent;
        box-shadow: $card-resting-shadow;
        cursor: default;
      }
    }
  }

  &_reset-height {
    height: auto;
  }

  @include device(tablet) {
    height: auto;
  }

  &__main {
    flex: 1;
    padding-right: 24px;

    &_with-icon {
      display: flex;

      @include device(phablet) {
        display: block;
      }
    }
  }

  &__icon {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-right: 16px;

    &_avatar {
      border-radius: 50%;
    }

    &,
    .app-icon {
      border-radius: $border-radius-m;
      height: 56px;
      overflow: hidden;
      width: 56px;
    }

    @include device(phablet) {
      margin: 0 0 12px;

      &,
      .app-icon {
        border-radius: $border-radius-s;
        height: 32px;
        width: 32px;
      }
    }
  }

  &__footer {
    border-top: 1px solid $border-subtle;
    justify-content: space-between;
    margin-top: 16px;
    padding-top: 10px;

    &:empty {
      display: none;
    }

    @include device(phablet) {
      padding-top: 16px;
    }

    &_columns {
      align-items: flex-end;
      display: flex;
      justify-content: space-between;

      @include device(tablet) {
        display: block;
      }
    }
  }

  &__footer-column {
    display: block;
    max-width: 50%;

    &_centered {
      align-items: center;
      display: flex;
    }

    &_no-overflow {
      overflow: hidden;
    }

    &_no-wrap {
      white-space: nowrap;
    }

    & + & {
      @include device(tablet) {
        margin-top: 4px;
      }
    }

    @include device(tablet) {
      max-width: none;
    }
  }

  &__footer-content {
    @include overflow-ellipsis;
  }

  &__footer-error {
    align-items: center;
    color: $error-fg;
    display: flex;
  }

  &__footer-icon {
    color: $icon-default;
    margin-right: 8px;

    &_error {
      color: $red-default;
    }
  }

  &__header {
    flex: 1;
    min-width: 0;
  }

  &__title {
    @include break-word;

    /* stylelint-disable-next-line property-no-vendor-prefix */
    -webkit-box-orient: vertical;
    color: $text-primary;
    /* stylelint-disable-next-line value-no-vendor-prefix */
    display: -webkit-box;
    font-size: 20px;
    font-weight: 700;
    -webkit-line-clamp: 2;
    line-height: 1.4;
    margin: 0 0 6px;
    max-height: 2.8em;
    overflow: hidden;
    text-overflow: ellipsis;

    @include device(phablet) {
      font-size: 16px;
      max-height: none;
    }
  }

  &__title-highlight {
    color: $highlight-color;
  }

  // Additional `&` to override `.w-tag-host` styles
  & &__title-tag,
  &__title-tags {
    margin-left: 8px;

    @include device(phablet) {
      display: block;
      margin-left: 0;
    }
  }

  &__subtitle {
    @include overflow-ellipsis;

    color: $text-secondary;

    @include device(phablet) {
      white-space: normal;
    }
  }

  &__subtitle-text {
    cursor: text;
    user-select: all;
  }

  &__date {
    font-weight: 600;
  }

  &__count {
    font-size: 20px;
    font-weight: 700;

    @include device(phablet) {
      font-size: 14px;
    }

    &_success {
      color: $success-fg;
    }

    &_inactive {
      color: $text-secondary;
    }
  }

  &__activity-indicator {
    background-color: $backdrop-default;
    border-radius: 50%;
    padding: 2px 7px;
    position: absolute;
    right: -1px;
    top: -1px;
    transition: background-color 0.3s ease;

    .w-card:not(.w-card_static):hover &,
    .w-card:not(.w-card_static):focus & {
      background-color: transparent;
    }
  }

  &__menu {
    position: absolute;
    right: 24px;
    top: 23px;

    @include device(phablet) {
      top: 20px;
    }
  }

  &__separator {
    display: inline-block;
    height: 20px;
    position: relative;
    vertical-align: text-bottom;
    width: 18px;

    &::after {
      background: $border-default;
      bottom: 0;
      content: "";
      left: 50%;
      position: absolute;
      top: 0;
      width: 1px;
    }
  }

  &__badge {
    display: inline-flex;
  }
}
