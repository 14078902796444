.w-resize-handler {
  background: image-url("resizable.svg") no-repeat;
  bottom: 0;
  cursor: ns-resize;
  height: 10px;
  position: absolute;
  right: 0;
  width: 10px;
  z-index: 10;
}
