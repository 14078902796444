@use "variables" as *;

.recipe-template-theme {
  $icon-colors: $blue-opacity-80, $slate-opacity-80, $olive-opacity-80, $brown-opacity-80, $teal-default,
    $indigo-opacity-80, $forest-opacity-80;

  @each $color in $icon-colors {
    $index: index($icon-colors, $color);

    &_#{$index} {
      background-color: $color;
    }
  }
}
