@use "variables" as *;
@use "mixins" as *;

.w-page-tile {
  background: $surface-default;
  border: 1px solid $border-subtle;
  border-radius: $border-radius-l;
  box-shadow: $card-resting-shadow;
  padding: 44px;

  @include device(tablet) {
    padding: 24px;
  }

  & + & {
    margin-top: 24px;
  }

  &__title {
    color: $text-primary;
    font-size: $h2-font-size;
    font-weight: 700;
    line-height: 1.2857;
    margin: 0 0 12px;

    &_small {
      font-size: $h4-font-size;
    }
  }

  &__content {
    margin-bottom: 32px;
  }
}
