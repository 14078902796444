@use "variables" as *;
@use "mixins" as *;

.settings-content {
  & + & {
    margin-top: 24px;
  }

  &_bordered {
    border-bottom: 1px solid $border-subtle;
  }

  &__row {
    column-gap: 64px;
    display: flex;
    flex-wrap: wrap;
  }

  &__row > &__row-item {
    flex: 1;
    margin: 0;

    &:first-child {
      border: none;
      margin: 0;
      max-width: fit-content;
      padding: 0;
      width: 100%;
    }

    &_flex {
      flex: 1 1 auto;
    }
  }

  &__title {
    font-weight: 600;
    margin-bottom: 5px;

    &_secondary {
      color: $text-secondary;
      font-weight: 700;
      line-height: 1.2;
      margin-bottom: 18px;
      text-transform: uppercase;
    }

    &_no-margin {
      margin: 0;
    }
  }

  &__subtitle {
    margin-bottom: 18px;
  }

  &__text {
    line-height: 1.6;
  }

  &__link {
    font-weight: 600;
    white-space: nowrap;

    @include all-link-states {
      color: $teal-fg;
    }
  }

  &__button {
    margin: 0 16px;
  }

  &__meter-bar {
    display: block;
    margin-bottom: 20px;

    .meter-bar {
      &__remaining {
        font-size: 14px;
      }
    }
  }
}

.settings-content-list {
  @include reset-list;

  &__item {
    align-items: center;
    display: flex;
    font-weight: 600;
    margin: 4px 0 21px;

    &_bigger {
      font-size: 16px;
      font-weight: 700;
      white-space: nowrap;
    }
  }

  &__item-suffix {
    color: $text-secondary;
    font-size: 16px;
    margin-left: 6px;
  }

  &__item-membership {
    @include overflow-ellipsis;
  }

  &__item-counter {
    font-size: $h4-font-size;
    line-height: 1;
  }

  &__addon-link {
    line-height: 1;
  }

  &__icon {
    margin-right: 8px;

    &_plan {
      color: $indigo-fg;
    }

    &_addon {
      background-color: $green-subtle;
      border-radius: $border-radius-s;
      color: $green-fg;
      padding: 4px;
    }

    &_renew {
      background-color: $indigo-subtle;
      border-radius: $border-radius-s;
      color: $indigo-fg;
      padding: 4px;
    }
  }
}

.settings-billing {
  display: flex;

  &__image {
    @include background-image-theme("credit-card");

    height: 48px;
    margin: 0 10px 10px -6px;
    width: 72px;
  }

  &__info-expires {
    color: $text-secondary;
    font-size: 12px;
  }
}
