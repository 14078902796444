@use "variables" as *;

.chart-axis {
  font-family: $primary-font-family;
  font-size: 12px;
  letter-spacing: 0.5px;

  line {
    color: $border-subtle;
  }

  .domain {
    color: transparent;
  }

  text {
    color: $text-primary;
    text-anchor: middle;
  }

  &_y {
    &.chart-axis_left text {
      text-anchor: end;
    }

    &.chart-axis_right text {
      text-anchor: start;
    }
  }

  &_x.chart-axis_align-to-edges {
    g:first-of-type {
      text {
        text-anchor: start;
      }
    }

    g:last-of-type {
      text {
        text-anchor: end;
      }
    }
  }

  &_label {
    text {
      color: $text-primary;
      text-anchor: start;
    }
  }
}
