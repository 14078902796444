@use "variables" as *;
@use "mixins" as *;

.team-role-header {
  &__title {
    align-items: center;
    display: flex;
  }

  &__editable-title {
    &_full-width {
      flex: 1 1 auto;
    }
  }

  &__switch {
    align-items: center;
    display: flex;
    margin-top: 12px;
  }

  &__switch-title {
    color: $text-secondary;
    font-size: 16px;
    margin: 0 0 0 12px;
  }

  &__floating-tag {
    font-size: $h5-font-size;
    font-weight: 400;
    line-height: 20px;
  }

  &__link {
    cursor: pointer;
  }
}
