@use "variables" as *;
@use "mixins" as *;

.CodeMirror {
  background: $surface-field;
  color: $text-primary;
  font: inherit;
  height: 100%;
  min-height: 44px;
  padding: 0 $editor-horizontal-padding;

  @include theme(dark) {
    @include codemirror-selection-color($material-theme-selection-color);
  }

  w-pill,
  lcap-pill {
    margin-left: 2px;
    margin-right: 2px;
    position: relative;
    top: -1px;
  }

  .CodeMirror-widget:first-child > w-pill,
  .CodeMirror-line > w-pill:first-child,
  .CodeMirror-widget:first-child > lcap-pill,
  .CodeMirror-line > lcap-pill:first-child {
    margin-left: 0;
  }
}

.CodeMirror-scroll {
  @include forced-scrollbar;

  max-height: $editor-max-height;
}

.CodeMirror-vscrollbar,
.CodeMirror-hscrollbar {
  @include forced-scrollbar;
}

.CodeMirror-placeholder {
  /* stylelint-disable-next-line declaration-no-important */
  color: $form-placeholder-text-color !important;
}

// Fixes huge performance bug in Chrome on pills drop
.CodeMirror-dragcursors {
  display: none;
}

.CodeMirror-cursor {
  border-color: $text-primary;
}

.CodeMirror-lines {
  padding: $editor-vertical-padding 0;
}

.CodeMirror-lint-tooltip {
  z-index: $code-editor-full-screen-z-index + 1;
}

.CodeMirror-fullscreen {
  height: auto;
  inset: 0;
  margin: 0 auto;
  position: absolute;
}

.CodeMirror-lint-marker-error,
.CodeMirror-lint-message-error {
  background-image: image-url("code-editor/error-marker.svg");
  background-size: 14px 14px;
}

// Codemirror themes customisation

.cm-s-mdn-like {
  @include codemirror-selection-color(Highlight);

  &.CodeMirror {
    background-image: none;
  }

  .CodeMirror-gutters {
    background: $surface-subtle;
    border: none;
  }

  .cm-string {
    font-style: normal;
  }

  .cm-comment {
    color: $mdn-like-theme-comment-color;
  }

  .cm-mustache {
    color: $orange-fg;
  }
}

.cm-s-material {
  @include codemirror-selection-color($material-theme-selection-color);

  &.CodeMirror {
    background: $material-theme-background;
    color: $material-theme-text-color;
  }

  .CodeMirror-gutters {
    background: $material-theme-background;
  }

  .pill {
    @include pill-dark-theme(".pill");
  }

  .lcap-pill {
    @include pill-dark-theme(".lcap-pill");
  }

  .insights-pill {
    @include pill-dark-theme(".insights-pill");
  }

  .cm-mustache {
    color: $orange-fg;
  }
}
