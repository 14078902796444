@use "variables" as *;
@use "mixins" as *;

.w-page-main {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: $guest-header-height 0 0;

  @include without-nav {
    padding: 0;
  }

  &__sticky-area {
    display: block;
    position: sticky;
    top: $guest-header-height;
    z-index: $header-z-index - 1;

    @include without-nav {
      top: 0;
    }
  }
}
