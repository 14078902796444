@use "variables" as *;

@mixin color-overlay($color, $border-radius: inherit, $offset: 0) {
  @include color-overlay-border-radius($border-radius);
  @include color-overlay-offset($offset);

  &::after {
    background: $color;
    content: "";
    position: absolute;
  }
}

@mixin color-overlay-border-radius($border-radius: 0) {
  &::after {
    border-radius: $border-radius;
  }
}

@mixin color-overlay-offset($top: 0, $right: $top, $bottom: $top, $left: $right) {
  &::after {
    inset: $top $right $bottom $left;
  }
}
