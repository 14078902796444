@use "variables" as *;
@use "w-form-control" as *;

.w-form-select {
  display: block;
  margin-bottom: 0;
  position: relative;

  select {
    @extend .w-form-control;

    /* stylelint-disable property-no-vendor-prefix */
    // Hiding native select controls in FF
    -moz-appearance: none;
    // ... and Webkit/Blink browsers
    -webkit-appearance: none;
    /* stylelint-enable */
    cursor: pointer;
    height: 40px;
    padding-right: 28px;

    /* stylelint-disable-next-line selector-no-qualifying-type */
    &[disabled] {
      background: $form-disabled-background;
      border-color: $form-disabled-border;
      color: $form-disabled-text-color;
      cursor: default;
    }

    // Hiding arrow in IE
    &::-ms-expand {
      display: none;
    }

    // Removing blue highlight in IE when select is focused
    &:focus::-ms-value {
      background-color: inherit;
      color: inherit;
    }

    // When `select` element has attribute `required` and selected option has empty value
    &:invalid {
      color: $text-primary;
      font-style: italic;
    }

    // Removing ugly dotted outline in FF
    // http://stackoverflow.com/a/18853002
    &:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 $text-primary;

      &:invalid {
        color: transparent;
        text-shadow: 0 0 0 $text-primary;
      }
    }

    // In FF `option` elements inherits `select` text color and style so when it has `:invalid`
    // state all the options in dropdown have wrong font style
    option {
      color: $text-primary;
      font-style: normal;
    }
  }

  &::after {
    border: 5px solid transparent;
    border-top-color: $form-disabled-border;
    content: "";
    height: 0;
    margin-top: -1px;
    pointer-events: none;
    position: absolute;
    right: 11px;
    top: 50%;
    width: 0;
  }
}
