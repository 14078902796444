@use "variables" as *;
@use "mixins" as *;

.error-page {
  $cta-button-width: 180px;
  $cta-button-margin: 5px;
  $cta-buttons-in-a-row: 3;

  &__content {
    padding: 130px 100px;
    text-align: center;

    @include device(phablet) {
      padding: 32px 40px;
    }
  }

  &__img {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: block;
    height: 200px;
    margin: 0 auto 24px;
    width: 240px;

    &_default {
      @include background-image-theme("error/page-error", "svg", true);
    }

    &_genie {
      @include background-image-theme("error/page-error_genie", "svg");
    }

    @include device(tablet) {
      height: 190px;
      width: 228px;
    }

    @include device(pahblet) {
      height: 180px;
      width: 216px;
    }
  }

  &__title {
    font-size: 24px;
    font-weight: 700;
    line-height: 1.2;
    margin: 0;

    @include device(phablet) {
      font-size: 16px;
    }
  }

  &__description {
    font-size: 14px;
    margin-top: 4px;
  }

  &__cta {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 24px auto 0;
    max-width: ($cta-button-width + 2 * $cta-button-margin) * $cta-buttons-in-a-row;

    @include device(phablet) {
      margin-top: 16px;
    }
  }
}
