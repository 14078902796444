@use "variables" as *;
@use "mixins" as *;
@use "shared/mixins/button-focus-outline" as *;

w-header-link {
  // Removed excessive space inside the tag
  line-height: 0;
}

.header-link {
  @include reset-button;

  border-radius: 2px;
  color: $neutral-muted;
  cursor: pointer;
  display: inline-block;
  font-size: inherit;
  line-height: 1;
  margin: 0 auto;
  text-align: left;
  text-decoration: none;
  vertical-align: middle;
  white-space: nowrap;

  @include device(compact) {
    color: $accent-fg-emphasis;
    display: block;
    font-size: 15px;
    font-weight: 700;
    opacity: 0.7;
    padding: 16px 40px;
  }

  &:focus,
  &:active,
  &:hover,
  &_active {
    color: $text-inverse;
    text-decoration: none;

    @include device(compact) {
      color: $accent-fg-emphasis;
      opacity: 1;
    }
  }

  &:focus:not(.focus-visible) {
    outline: none;
  }

  &_active {
    &::after {
      border-bottom: 5px solid $border-inverse;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      bottom: 0;
      content: "";
      height: 0;
      left: 50%;
      margin: 0 0 0 -5px;
      position: absolute;
      width: 0;
    }

    @include device(compact) {
      background: $surface-subtle;

      &::after {
        display: none;
      }
    }
  }

  .header_theme_light & {
    &,
    &:focus,
    &:active,
    &:hover {
      color: $accent-fg-emphasis;
    }
  }

  &_fake-button {
    background: $surface-default;
    margin: 0;
    opacity: 0.7;
    text-align: left;
  }

  &_logout-button {
    width: 100%;
  }

  &_button {
    border: 1px solid;
    border-radius: 2px;
    min-width: 86px;
    padding: 8px 10px;
    text-align: center;

    &,
    &:hover,
    &:focus,
    &:active {
      border-color: $accent-border-emphasis;
      color: $accent-fg-emphasis;
    }

    @include device(compact) {
      border: none;
      margin: 0 auto;
      text-align: left;
    }

    .header_theme_light & {
      &,
      &:hover,
      &:focus,
      &:active {
        background: $orange-default;
        border-color: $orange-default;
        color: $fg-onOrange;
      }

      &:hover {
        background: $orange-emphasis;
        border-color: $orange-emphasis;
      }
    }
  }

  &_icon {
    font-size: 18px;
  }

  &_dropdown {
    cursor: pointer;
    padding-right: 15px;
    position: relative;

    &.header-link_active {
      background: none;

      &::after {
        display: none;
      }
    }

    @include device(compact) {
      padding-right: 0;
    }
  }

  &_all {
    text-align: center;
  }

  &__chevron {
    margin-left: 8px;
    position: absolute;
    right: 0;
    top: calc(50% - 2.5px);
    transform: rotate(90deg);
    transition: transform 0.25s ease-in-out;

    .header-link_active & {
      transform: rotate(270deg);
    }

    @include device(compact) {
      position: relative;
      top: 0;
    }
  }

  .header-item_user & {
    @include device(compact) {
      color: $text-inverse;
      font-weight: 400;
      opacity: 1;
      padding-bottom: 25px;
      padding-top: 25px;

      &:hover {
        background: $neutral-dark;
        color: $fg-onNeutral-dark;
      }
    }
  }

  .header-item_subscribe & {
    @include device(compact) {
      background: $orange-default;
      border-radius: 0;
      color: $fg-onOrange;
      font-weight: 400;
      opacity: 1;
      padding: 25px 40px;
    }
  }

  .header-dropdown & {
    border-radius: 2px;
    display: block;
    font-size: inherit;
    line-height: 1.4;
    padding: 9px 10px;
    white-space: nowrap;

    &_button {
      @include button-focus-outline;

      background: none;
      border: none;
      cursor: pointer;
      display: block;
      text-align: left;
      width: 100%;
    }

    &,
    &:focus,
    &:active,
    &:hover {
      color: $text-primary;
      text-decoration: none;
    }

    &_button:focus,
    &_button:active,
    &_button:hover {
      border: none;
    }

    &:hover {
      background: $surface-subtle;
    }

    &_active,
    &_active:hover {
      background: $accent-tint;
    }

    &::after {
      display: none;
    }

    .header_theme_light &_all {
      background: $surface-subtle;
    }

    @include device(compact) {
      margin: 0 auto;
      padding: 10px 30px;
      text-align: left;
      white-space: normal;

      &:hover {
        background: none;
      }

      &_active,
      &_active:hover {
        background: $accent-tint;
      }
    }
  }
}
