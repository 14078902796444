@use "variables" as *;

@mixin resource-type-background {
  &_all {
    background-color: $resource-default-background-color;
    color: $resource-default-color;
  }

  &_account {
    background-color: $resource-user-background-color;
    color: $resource-user-color;
  }

  &_account_property,
  &_project_property {
    background-color: $resource-account-property-background-color;
    color: $resource-account-property-color;
  }

  &_folder,
  &_project {
    background-color: $resource-folder-background-color;
    color: $resource-folder-color;
  }

  &_api_group {
    background-color: $resource-api-background-color;
    color: $resource-api-color;
  }

  &_api_policy {
    background-color: $resource-api-background-color;
    color: $resource-api-color;
  }

  &_api_client {
    background-color: $resource-api-background-color;
    color: $resource-api-color;
  }

  &_api_access_profile {
    background-color: $resource-api-background-color;
    color: $resource-api-color;
  }

  &_api_endpoint {
    background-color: $resource-api-background-color;
    color: $resource-api-color;

    &_get {
      background-color: $resource-api-endpoint-get-color;
      color: $fg-onIndigo;
    }

    &_post {
      background-color: $resource-api-endpoint-post-color;
      color: $fg-onForest;
    }

    &_put {
      background-color: $resource-api-endpoint-put-color;
      color: $fg-onRust;
    }

    &_patch {
      background-color: $resource-api-endpoint-patch-color;
      color: $fg-onSlate;
    }

    &_delete {
      background-color: $resource-api-endpoint-delete-color;
      color: $fg-onRed;
    }
  }

  &_custom_adapter {
    background-color: $resource-custom-adapter-background-color;
    color: $resource-custom-adapter-color;
  }

  &_topic {
    background-color: $resource-topic-background-color;
    color: $resource-topic-color;
  }

  &_workato_schema {
    background-color: $resource-workato-schema-background-color;
    color: $resource-workato-schema-color;
  }

  &_workato_template {
    background-color: $resource-workato-template-background-color;
    color: $resource-workato-template-color;
  }

  &_lookup_table {
    background-color: $resource-lookup-table-background-color;
    color: $resource-lookup-table-color;
  }

  &_workato_db_table {
    background-color: $resource-table-background-color;
    color: $resource-table-color;
  }

  &_connection {
    background-color: $resource-connection-background-color;
    color: $resource-connection-color;
  }

  &_recipe {
    background-color: $resource-recipe-background-color;
    color: $resource-recipe-color;
  }

  &_package {
    background-color: $resource-package-background-color;
    color: $resource-package-color;
  }

  &_teams {
    background-color: $resource-teams-background-color;
    color: $resource-teams-color;
  }

  &_user {
    background-color: $resource-user-background-color;
    color: $resource-user-color;
  }

  &_audit_log_replication_settings {
    background-color: $resource-user-background-color;
    color: $resource-user-color;
  }

  &_workflow_task {
    background-color: $resource-workflow-tasks-background-color;
    color: $resource-workflow-tasks-color;
  }

  &_provider {
    background-color: $resource-provider-background-color;
    color: $resource-provider-color;
  }

  &_deployment,
  &_project_build {
    background-color: $resource-deployment-background-color;
    color: $resource-deployment-color;
  }

  &_lcap_page {
    background-color: $resource-lcap-page-background-color;
    color: $resource-lcap-page-color;
  }

  &_lcap_app {
    background-color: $resource-lcap-app-background-color;
    color: $resource-lcap-app-color;
  }

  &_lcap_portal {
    background-color: $resource-lcap-portal-background-color;
    color: $resource-lcap-portal-color;
  }

  &_test_case {
    background-color: $resource-test-case-background-color;
    color: $resource-test-case-color;
  }

  &_insights_dashboard {
    background-color: $resource-insights-background-color;
    color: $resource-insights-color;
  }

  &_data_pipeline {
    background-color: $resource-data-pipeline-background-color;
    color: $resource-data-pipeline-color;
  }

  &_tag {
    background-color: $resource-tag-background-color;
    color: $resource-tag-color;
  }
}
