// uses $default-line-height value for $line-height
@mixin line-clamp($lines, $use-max-height: true, $line-height: 1.4286) {
  $height: #{$lines * $line-height}em;

  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-box-orient: vertical;
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  line-height: $line-height;
  overflow: hidden;
  text-overflow: ellipsis;

  @if $use-max-height {
    max-height: $height;
  } @else {
    height: $height;
  }
}
