@use "variables" as *;
@use "mixins/media-queries" as *;

.recipe-steps {
  padding-left: $recipe-steps-padding;
  z-index: 2;

  @include device(phone) {
    padding-left: $recipe-steps-padding-phone;
  }

  &__title {
    color: $recipe-step-title-color;
    display: inline-block;
    font-size: 12px;
    font-weight: 600;
    height: $recipe-steps-title-height;
    margin: 0 0 $recipe-steps-title-bottom-margin -8px;
    padding: 8px 8px 4px;
    pointer-events: none;
    position: relative;
    text-transform: uppercase;
    user-select: none;
    z-index: 1;

    @include device(phone) {
      padding-left: $recipe-steps-padding-phone;
    }
  }
}
