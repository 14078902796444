@use "variables" as *;
@use "mixins" as *;

.burger-menu {
  @include reset-button;

  cursor: pointer;
  height: 21px;
  margin: 18px auto;
  position: relative;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
  width: 26px;
  z-index: 5;

  &__item {
    background: $surface-default;
    border-radius: 3px;
    display: block;
    height: 3px;
    left: 0;
    opacity: 1;
    position: absolute;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
    width: 100%;

    &:nth-child(1) {
      top: 0;
    }

    &:nth-child(2),
    &:nth-child(3) {
      top: 9px;
    }

    &:nth-child(4) {
      top: 18px;
    }

    .burger-menu_guest & {
      background: $mktg-black;
    }
  }

  &_open {
    .burger-menu__item {
      .burger-menu_guest & {
        background: $surface-default;
      }
    }

    .burger-menu__item:nth-child(1) {
      left: 50%;
      top: 8px;
      width: 0;
    }

    .burger-menu__item:nth-child(2) {
      transform: rotate(45deg);
    }

    .burger-menu__item:nth-child(3) {
      transform: rotate(-45deg);
    }

    .burger-menu__item:nth-child(4) {
      left: 50%;
      top: 8px;
      width: 0;
    }
  }
}
