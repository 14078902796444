@use "variables" as *;
@use "mixins" as *;

.connection-page {
  &__search {
    display: block;
    margin-bottom: 24px;
  }

  &__tile {
    background: $surface-default;
    border: 1px solid $border-subtle;
    border-radius: $border-radius-l;
    padding: 44px;

    @include device(phablet) {
      padding: 24px;
    }
  }

  &__aside-logs {
    display: block;
    margin-top: 8px;
  }

  &__adapters-groups {
    .adapter-list-groups {
      &__group {
        margin-bottom: 64px;

        &_no-title {
          margin-top: 0;
        }
      }

      &__group-title.section-title {
        margin: 0 0 32px;
      }
    }
  }

  &__adapters-footer {
    border-top: 1px solid $border-subtle;
    color: $text-secondary;
    margin-top: 36px;
    padding-top: 16px;
  }

  &__status-text {
    @include overflow-ellipsis;

    min-width: 0;
  }
}
