@use "variables" as *;

.auth-form {
  &__content {
    margin: 0 0 20px;
  }

  &__actions {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 24px;
  }

  &__action {
    // we need to reset style because `w-button + w-button` have margin left
    margin: 0;
  }

  &__info {
    margin-top: $gap-m;
    text-align: center;

    &_start {
      text-align: left;
    }
  }

  a:not(.button) {
    color: $text-secondary;

    &:hover {
      color: $text-secondary;
    }
  }
}
