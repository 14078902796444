@use "variables" as *;
@use "mixins" as *;

w-logo {
  display: inline-flex;
}

.logo {
  align-items: center;
  color: $text-inverse;
  display: inline-flex;
  font-size: 26px;
  line-height: 0.7;
  outline: none;
  text-decoration: none;
  white-space: nowrap;

  &__workato {
    color: $auth-logo-color;
    margin-top: -1px;

    &_black {
      color: $mktg-black;
    }

    &_gray {
      color: $icon-default;
    }

    @include device(tablet) {
      margin-top: 0;
    }
  }

  &__oem {
    font-size: 0;
    height: auto;
  }

  &:focus,
  &:not(.logo_passive):hover {
    color: $text-inverse;
    opacity: 0.7;
    text-decoration: none;
  }

  &_guest {
    .logo__workato {
      color: $mktg-black;
    }
  }

  &__icon {
    height: 30px;
    margin-right: 20px;
    width: 30px;
  }

  &__company-name {
    display: inline-block;
    font-size: 18px;
    line-height: 1.3;
    margin-right: 15px;
    vertical-align: middle;
  }

  &__title {
    border-left: 1px solid $border-subtle;
    color: $text-secondary;
    display: block;
    font-size: 14px;
    padding: 13px 0 10px 15px;
  }
}
