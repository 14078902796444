@use "variables" as *;
@use "mixins" as *;

.community-info {
  &__data {
    border-bottom: 1px solid $border-subtle;
    display: flex;
    padding-bottom: 16px;
  }

  &__listing-info {
    color: $text-secondary;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-top: 8px;
  }

  &__installs {
    background: $surface-inverse-opacity-5;
    border-left: 2px solid $border-subtle;
    border-radius: 1px;
    border-right: 2px solid $border-subtle;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 16px 28px;
    text-align: center;
    white-space: nowrap;
  }

  &__installs-value {
    font-size: 24px;
    font-weight: 600;
    line-height: 1.2;
    margin-right: 4px;
  }

  &__link {
    margin-top: 4px;
    white-space: nowrap;
  }

  &__details {
    display: flex;
    flex-grow: 1;
    margin-left: 24px;
    overflow: hidden;
  }

  &__details-rows {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;

    $installation-method-width: 119px;
    $published-under-width: 99px;

    &_installation-method {
      @include device-min(compact) {
        .community-info__details-cell:first-of-type {
          flex-basis: $installation-method-width;
          flex-grow: 0;
          min-width: $installation-method-width;
        }
      }
    }

    &_published-under {
      @include device-min(compact) {
        .community-info__details-cell:first-of-type {
          flex-basis: $published-under-width;
          flex-grow: 0;
          min-width: $published-under-width;
        }
      }
    }
  }

  &__details-row {
    display: flex;

    & + & {
      padding-top: 4px;
    }
  }

  &__details-cell {
    display: flex;
    flex-grow: 1;
    line-height: 20px;
    overflow: hidden;

    & + & {
      padding-left: 24px;
    }
  }

  &__company-website {
    white-space: nowrap;
  }

  &__company-name,
  &__company-email {
    @include overflow-ellipsis;
  }

  @include device(compact) {
    &__installs {
      padding: 66px 29px;
    }

    &__details,
    &__details-rows,
    &__details-row {
      display: flex;
      flex-direction: column;
    }

    &__details-cell {
      width: auto;

      & + & {
        padding-left: 0;
      }

      &:first-of-type {
        flex-basis: auto;
        min-width: auto;
        white-space: nowrap;
      }
    }
  }

  @include device(tablet) {
    &__data {
      flex-direction: column;
    }

    &__details {
      margin-left: 0;
    }

    &__installs {
      flex-grow: 0;
      margin-bottom: 24px;
      padding: 16px 28px;
    }

    &__separator {
      display: none;
    }

    &__company-name,
    &__company-email {
      max-width: none;
      white-space: normal;
      word-break: break-word;
    }

    &__details-cell_company {
      flex-direction: column;
    }
  }

  &__published-text {
    vertical-align: middle;
  }
}
