@use "variables" as *;
@use "mixins" as *;

.w-page-content {
  margin-bottom: $page-content-margin-bottom;
  margin-top: 32px;
  min-height: 600px;

  &_no-mb {
    margin-bottom: 0;
  }

  &,
  &__container {
    @include container;

    display: flex;
    flex: 1;
    min-width: 0;

    @include device(desktop-small) {
      display: block;
    }

    &_single {
      display: block;
    }
  }

  &__container {
    padding: 0;
  }

  &_reverse-on-desktop-small {
    @include device(desktop-small) {
      display: flex;
      flex-direction: column-reverse;
      justify-content: flex-end;
    }
  }

  &__main {
    flex: 1 1 auto;
    min-width: 0;
    order: 1;

    &_medium {
      max-width: 1128px;
    }

    &_small {
      max-width: $page-small-content-max-width;

      @include device(desktop-small) {
        max-width: none;
      }
    }

    .w-page-content_reverse & {
      order: 2;
    }

    .w-page-content_reverse-on-desktop-small & {
      @include device(desktop-small) {
        width: 100%;
      }
    }
  }

  &__aside {
    flex-shrink: 0;
    margin-left: $page-aside-margin;
    max-width: 360px;
    min-width: 264px;
    order: 2;
    width: 25%;

    @include device(desktop-small) {
      margin: $page-aside-compact-margin 0 0;
      max-width: none;
      width: 100%;
    }

    &_large {
      max-width: none;
    }

    .w-page-content_reverse & {
      margin: 0 $page-aside-margin 0 0;
      order: 1;

      @include device(desktop-small) {
        margin: 0 0 $page-aside-compact-margin;
      }
    }

    .w-page-content_reverse .w-page-content__main + & {
      @include device(desktop-small) {
        margin: $page-aside-compact-margin 0 0;
      }
    }

    .w-page-content_reverse-on-desktop-small & {
      @include device(desktop-small) {
        margin: 0 0 $page-aside-compact-margin;
        width: 100%;
      }
    }

    .w-page-global_size_small & {
      width: 360px;
    }
  }

  &__spinner {
    display: flex;
    justify-content: center;
    margin: 30px auto;
  }

  &__empty {
    color: $text-secondary;
    font-style: italic;
  }

  &__search-bar {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    @include device(phablet) {
      flex-direction: column-reverse;
    }
  }

  &__search-bar-button {
    display: inline-block;
    margin-left: 16px;

    @include device(phablet) {
      margin-bottom: 16px;
      margin-left: 0;
    }
  }

  &__search-field {
    display: block;
    flex: 1 1 auto;
    margin-bottom: 24px;

    &_small-margin {
      margin-bottom: 12px;
    }

    .w-page-content__search-bar & {
      margin-bottom: 0;
      max-width: 288px;

      @include device(phablet) {
        max-width: none;
      }
    }
  }

  &__notification {
    display: block;
    margin-bottom: 16px;
  }

  /* stylelint-disable-next-line selector-no-qualifying-type */
  &__title,
  h2.w-page-content__title {
    @include break-word;

    color: $text-secondary;
    font-size: 16px;
    font-weight: 700;
  }
}
