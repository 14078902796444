@use "variables" as *;
@use "mixins" as *;

.header-item {
  align-items: center;
  color: $neutral-muted;
  display: flex;
  height: $guest-header-height;
  padding: 0 15px;
  position: relative;

  @include device(compact) {
    display: block;
    height: auto;
    padding: 0;
  }

  &_user {
    @include device(compact) {
      background: $neutral-dark;
      order: 1;
      width: 100%;
    }
  }

  &_subscribe {
    @include device(compact) {
      order: 2;
      width: 100%;
    }
  }

  &__dropdown {
    left: 0;
    position: absolute;
    top: 100%;
    width: 100%;

    @include device(compact) {
      position: relative;

      .header-item_user & {
        position: absolute;
      }
    }
  }
}
