@mixin theme($theme, $root-selector: "", $local-selector: "") {
  @if $theme == light {
    $root-selector: $root-selector + ".light-theme";
    $local-selector: ".dark-theme";
  }

  @if $theme == dark {
    $root-selector: $root-selector + ".dark-theme";
    $local-selector: ".light-theme";
  }

  @scope (#{$root-selector}) to (#{$local-selector}) {
    @content;
  }

  #{$root-selector}:not(:has(#{$local-selector})) &:not(.supports-scoped-css #{&}) {
    @content;
  }

  @supports not selector(:has(a, b)) {
    #{$root-selector} & {
      @content;
    }
  }
}

@mixin oem-theme($theme) {
  @include theme($theme, ".oem-theme") {
    @content;
  }
}
