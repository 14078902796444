@use "theme" as *;

// Codemirror variables
$editor-max-height: 514px;
$editor-horizontal-padding: 8px;
$editor-vertical-padding: 9px;
$editor-expand-button-spacing: 20px;
$editor-reveal-button-spacing: 36px;
$editor-calendar-button-spacing: 28px;

$form-field-spacing: 24px;

$form-background: $surface-field;
$form-secondary-background: $surface-alt;
$form-disabled-background: $surface-subtle;
$form-formula-background: $neutral-emphasis;
$form-error-background: $error-subtle;
$form-error-disabled-background: $error-muted-opacity-15;
$form-warning-background: $warning-subtle;
$form-warning-disabled-background: $warning-muted-opacity-15;

$form-hint-text-color: $text-secondary;
$form-placeholder-text-color: $text-placeholder;
$form-disabled-text-color: $text-secondary;
$form-type-disabled-text-color: $text-disabled;
$form-error-text-color: $error-fg-emphasis;
$form-warning-text-color: $warning-fg-emphasis;
$form-formula-sign-text-color: $fg-onNeutral;

$form-border: $neutral-border;
$form-formula-border: $neutral-border;
$form-hover-border: $neutral-border-emphasis;
$form-active-border: $accent-border-emphasis;
$form-disabled-border: $border-default;
$form-error-border: $error-border;
$form-error-active-border: $error-border-emphasis;
$form-warning-border: $warning-border;
$form-warning-active-border: $warning-border-emphasis;
