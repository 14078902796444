@use "variables" as *;
@use "mixins" as *;

/* stylelint-disable declaration-no-important */
@each $name, $adapter in adapters() {
  $color: map_get($adapter, "color");
  $theme: map_get($adapter, "theme");
  $background-color: if($theme == "brand", $utility-bg-teal, $color);
  $border-color: if($theme == "brand", $utility-border-opacity-20, $color);

  .appicon-#{$name} {
    @if $theme == "brand" {
      background-color: $background-color !important;
      border: 1px solid $border-color !important;
    } @else {
      $lightness: lightness($color);

      background-color: $color !important;
      border: 1px solid $color !important;

      @if $lightness > $light-app-icon-max-lightness {
        @include theme(light) {
          border-color: $border-default !important;
        }
      }

      @if $lightness < $dark-app-icon-min-lightness {
        @include theme(dark) {
          border-color: $border-default !important;
        }
      }
    }
  }

  @if map_get($adapter, "secondary") {
    $lightness: lightness($color);

    .appicon-#{$name}__secondary {
      @if $theme == "brand" {
        border-color: $utility-bg-teal !important;
      } @else if $lightness < $light-app-icon-max-lightness {
        border-color: $color !important;
        color: $color !important;
      }
    }
  }
}
/* stylelint-enable */
