@use "variables" as *;
@use "mixins" as *;

.member-form {
  $spacing-size: 25px;

  &__section {
    &_small-width {
      max-width: $page-small-content-max-width;

      @include device(compact) {
        max-width: 100%;
      }
    }

    &_with-margin {
      margin-bottom: $form-field-spacing;
    }
  }

  margin-bottom: $spacing-size;

  &__error {
    color: $form-error-text-color;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  &__saml {
    margin-bottom: $form-field-spacing;
    margin-top: $spacing-size;
  }

  &__message {
    margin-bottom: $spacing-size;
  }

  &__current-mark {
    color: $text-secondary;
  }

  &__section-header-title {
    align-items: center;
    display: flex;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    margin: 0;
  }

  &__section-header-sub-title {
    margin-top: 4px;
  }

  &__section-content {
    margin-top: 24px;
  }
}
