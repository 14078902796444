@use "variables" as *;
@use "mixins" as *;

.w-page-sidebar {
  $margin-horizontal: 32px;
  $margin-bottom: 16px;

  background: $surface-default;
  border: 1px solid $border-subtle;
  border-radius: $border-radius-l;
  overflow: hidden;
  padding: 8px 0 $margin-bottom;
  position: relative;

  &__status {
    @include reset-button;
    @include overflow-ellipsis;

    align-items: center;
    background-color: $surface-inverse-opacity-5;
    border-radius: $border-radius-m;
    color: $neutral-fg-emphasis;
    display: flex;
    font-size: 20px;
    font-weight: 700;
    margin: 8px $margin-horizontal * 0.5 0;
    padding: 16px;
    text-align: left;
    width: calc(100% - #{$margin-horizontal});

    &_positive {
      background-color: $green-subtle-opacity-50;
      color: $green-fg;

      .w-page-sidebar__status-icon,
      .w-page-sidebar__status-toggle {
        color: $green-fg;
      }
    }

    &_negative {
      background-color: $red-subtle-opacity-50;
      color: $red-fg;

      .w-page-sidebar__status-icon,
      .w-page-sidebar__status-toggle {
        color: $red-fg;
      }
    }

    &_info {
      background-color: $blue-muted-opacity-15;
      color: $blue-fg;

      .w-page-sidebar__status-icon,
      .w-page-sidebar__status-toggle {
        color: $blue-fg;
      }
    }

    &_warning {
      background-color: $warning-muted-opacity-15;
      color: $warning-fg-emphasis;

      .w-page-sidebar__status-icon,
      .w-page-sidebar__status-toggle {
        color: $warning-fg;
      }
    }

    &_notice {
      background-color: $notice-tint;
      color: $notice-fg;

      .w-page-sidebar__status-icon,
      .w-page-sidebar__status-toggle {
        color: $notice-fg;
      }
    }

    &_gold {
      background-color: $gold-tint;
      color: $gold-fg;

      .w-page-sidebar__status-icon,
      .w-page-sidebar__status-toggle {
        color: $gold-fg;
      }
    }

    @include device(desktop-small) {
      cursor: pointer;
    }
  }

  &__status-toggle-icon {
    transition: transform 0.15s $w-ease-in;

    .w-page-sidebar__status_expanded & {
      transform: rotate(-180deg);
    }
  }

  &__status-icon {
    color: $icon-default;
    margin-right: 8px;
  }

  &__status-toggle {
    align-items: center;
    color: $icon-default;
    display: flex;
    margin-left: auto;
  }

  &__content {
    border-bottom: 1px solid $border-subtle;
    margin: 24px $margin-horizontal;
    padding-bottom: 24px;

    &:last-child {
      border-bottom: none;
      margin-bottom: $margin-bottom;
      padding-bottom: 0;
    }

    &_no-border {
      border-bottom: none;
      padding-bottom: 0;
    }
  }

  &__content-row {
    & ~ & {
      margin-top: 12px;
    }
  }

  &__content-header {
    position: relative;

    &::before {
      border-bottom: 1px solid $border-subtle;
      content: "";
      height: 1px;
      left: 0;
      position: absolute;
      right: 0;
      top: 50%;
    }
  }

  &__content-header-title {
    background: $surface-elevated;
    border: 1px solid $border-subtle;
    border-radius: 32px;
    box-shadow: $card-resting-shadow;
    color: $text-secondary;
    font-size: 12px;
    margin: 0 auto;
    padding: 8px 0;
    position: relative;
    text-align: center;
    width: 200px;
    z-index: 1;
  }

  &__columns {
    display: flex;
    gap: 8px;
    justify-content: space-between;
  }

  &__column {
    flex: 1 1 0;
    min-width: 0;

    &_center-text {
      text-align: center;
    }
  }

  &__title {
    font-size: 16px;
    font-weight: 600;
    margin: 0 0 8px;

    .w-page-sidebar__text ~ & {
      margin-top: 20px;
    }

    &_uppercase {
      text-transform: uppercase;
    }
  }

  &__text {
    display: block;
    font-size: 16px;
    margin-bottom: 8px;
    overflow-wrap: anywhere;

    &:last-child {
      margin-bottom: 0;
    }

    &::first-letter {
      text-transform: capitalize;
    }

    &_no-text-transform {
      &::first-letter {
        text-transform: none;
      }
    }

    &_error {
      color: $error-fg;
    }

    &_secondary {
      color: $text-secondary;
    }

    &_truncated {
      @include line-clamp(2);
    }

    &_small {
      font-size: 14px;
    }
  }

  &__empty-text {
    color: $text-placeholder;
    font-size: 16px;
  }

  &__secondary-title {
    color: $text-secondary;
    font-size: 14px;
    font-weight: 400;
    margin: 16px 0 0;

    &:first-child {
      margin-top: 0;
    }

    &_with-margin {
      &,
      &:first-child {
        margin-top: 16px;
      }
    }
  }

  &__statistic {
    color: $text-secondary;
    font-size: 16px;
    font-weight: 700;

    &_success {
      color: $success-fg;
    }

    &_failed {
      color: $error-fg;
    }

    &_primary {
      color: $text-primary;
    }

    &_info {
      color: $blue-fg;
    }
  }

  &__statistic-title {
    color: $text-secondary;
    font-weight: 400;
    margin: 0;

    &_nowrap {
      @include overflow-ellipsis;
    }
  }

  &__help-link {
    display: block;
    margin: 8px 0 0;
  }

  &__footer {
    background: $surface-inverse-opacity-5;
    border-top: 1px solid $border-subtle;
    color: $text-secondary;
    font-size: 12px;
    margin-bottom: $margin-bottom * -1;
    padding: 12px 12px 12px 32px;
  }

  &__footer-icon {
    color: $icon-placeholder;
    margin-left: -18px;
    margin-top: 4px;
    position: absolute;
  }

  &_no-status {
    padding-top: 8px;
  }

  &__tags-container {
    font-size: 16px;
    padding-top: 4px;

    &_empty#{&}_readonly {
      padding-top: 0;
    }
  }
}
