.adapter-select-filter-option {
  align-items: center;
  display: flex;
  overflow: hidden;

  w-appicon {
    height: 28px;
    margin-right: 15px;
  }
}
