@use "variables" as *;
@use "mixins" as *;

.w-form {
  &__fieldset {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
  }

  &__element {
    flex: 1 1 50%;
    margin: 9px 10px;
    max-width: 100%;
    order: 1;

    &_half {
      flex: 0 1 calc(50% - 20px);

      @include device(tablet) {
        flex-basis: 100%;
      }
    }

    &_compact {
      flex: 0 0 auto;
      width: auto;
    }
  }

  &__info {
    color: $text-secondary;
    margin: 15px 0 0;
    text-align: center;

    &_start {
      text-align: left;
    }
  }

  &__info-link {
    text-decoration: underline;

    @include all-link-states {
      color: inherit;
    }

    &:hover {
      text-decoration: none;
    }
  }

  &__terms {
    font-weight: 600;

    &_hint {
      @include all-link-states {
        color: inherit;
      }

      font-weight: 400;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &__content {
    margin: 0 0 20px;
  }

  &__subaction {
    font-size: 18px;
    margin: 15px 0;
    text-align: center;
  }

  &__action {
    margin-top: 10px;
    text-align: center;
  }
}

.w-form-group {
  margin-bottom: 10px;
  text-align: left;

  &_error .w-form-control,
  &_error .auth-form-control {
    &,
    &:hover,
    &:focus {
      border-color: $form-error-border;
    }
  }

  &_error .w-form-message_error {
    color: $form-error-text-color;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  &_spacing {
    &_normal {
      margin-bottom: 28px;

      .validation-hint {
        margin-bottom: -19px;
      }
    }

    &_small {
      margin-bottom: 20px;
    }
  }

  .w-form__element_parameter & {
    margin-bottom: 0;
  }
}

.w-form-label {
  color: $text-primary;
  display: block;
  font-weight: 400;
  margin-bottom: 0;
  width: 100%;

  &__control {
    align-items: center;
    display: flex;
    margin-bottom: 3px;
  }

  &__control-label {
    font-weight: 400;
  }

  &__text {
    font-weight: 600;
    padding: 3px 0 8px 4px;

    .w-form_settings & {
      color: $text-primary;
      font-weight: 600;
      line-height: 1.35;
    }

    .w-form__element_parameter & {
      padding: 0;
    }
  }

  &__hint {
    color: $accent-fg;
    font-size: 12px;
    font-weight: 600;
    margin-top: -1.4em;

    &_color-inherit {
      color: inherit;
      font-weight: 400;
    }
  }

  &__checkbox[type="checkbox"] {
    margin: 0 5px 0 0;
  }
}

.w-form-message {
  @include break-word;

  font-size: 12px;
  font-weight: 400;
  line-height: 1.2;
  margin-top: 3px;
}

.w-form-alert {
  margin-bottom: 15px;
  white-space: pre-line;

  @include break-word;

  &_danger {
    color: $form-error-text-color;
  }
}

.w-field-group {
  display: block;

  &_with-prefix {
    align-items: stretch;
    display: flex;

    .w-form-control,
    .auth-form-control {
      border-radius: 0 2px 2px 0;
      flex: 1;
    }
  }

  &_with-icon {
    position: relative;

    .w-form-control,
    .auth-form-control {
      padding-left: 50px;
    }
  }

  &__prefix {
    align-items: center;
    background: $surface-alt;
    border: 1px solid $form-disabled-border;
    border-radius: 2px 0 0 2px;
    border-right: none;
    color: $text-secondary;
    display: flex;
    padding: 0 15px;
  }

  &__ico {
    align-items: center;
    background: inherit;
    border-right: 1px solid $form-border;
    color: $icon-default;
    display: flex;
    font-size: 14px;
    height: 38px;
    justify-content: center;
    left: 1px;
    position: absolute;
    top: 1px;
    width: 38px;

    .w-form-control:focus ~ &,
    .auth-form-control:focus ~ & {
      border-color: $text-primary;
      color: $text-primary;
    }

    .auth-form-control:focus ~ & {
      border-color: $brand-default;
    }

    .w-form-group_error .w-form-control ~ &,
    .w-form-group_error .auth-form-control ~ & {
      background: $error-muted-opacity-15;
      border-color: $form-error-text-color;
      color: $form-error-text-color;
    }
  }
}
