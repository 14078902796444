@use "variables" as *;
@use "mixins" as *;

.bar-chart-label {
  @include overflow-ellipsis;

  color: $text-primary;
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  left: 0;
  line-height: 1;
  margin-top: -0.5em;
  max-width: 95%;
  position: absolute;
  top: 0;

  &_link {
    &,
    &:link,
    &:focus,
    &:hover,
    &:visited {
      color: $text-primary;
      text-decoration: none;
    }

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
