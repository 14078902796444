@use "variables" as *;

.chart-track {
  &__line {
    stroke: $neutral-border-emphasis;
  }

  &__circle {
    stroke: $border-inverse;
    stroke-width: 1.6;
  }
}
