@use "variables" as *;

.member-activity {
  &__container {
    align-items: center;
    display: flex;
  }

  &__separator {
    color: $border-subtle;
    margin: 0 7px;
  }

  &__time {
    color: $text-secondary;
  }
}
