@mixin all-link-states {
  &,
  &:link,
  &:visited,
  &:focus,
  &:hover,
  &:active {
    @content;
  }
}
