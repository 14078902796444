@use "mixins" as *;
@use "variables" as *;

.settings-section {
  &__admin_notification {
    display: block;
    margin-top: 10px;
  }

  &__notification {
    display: block;
    margin-bottom: 24px;
  }

  &__columns {
    display: flex;

    @include device(desktop-hd) {
      flex-direction: column;
    }
  }

  &__column_grow {
    flex-grow: 1;
  }

  &__column + &__column {
    margin-left: 48px;

    @include device(desktop-hd) {
      margin-left: 0;
      margin-top: 48px;
    }
  }

  &__column-title {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 4px;
    margin-top: 0;
  }

  &__actions {
    margin-top: 12px;
  }

  &__spinner,
  &__action {
    display: block;
    margin-bottom: 24px;
  }

  &__file-field {
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    width: 0.1px;
    z-index: -1;
  }

  &__card {
    border: 1px solid $border-default;
    border-radius: $border-radius-l;
    display: flex;
    flex-direction: column;
    gap: 24px;
    min-width: 360px;
    padding: 32px;
  }

  &__card-value,
  &__card-title {
    font-size: $h5-font-size;
    margin: 0;
  }

  &__card-title {
    font-weight: 700;
    margin-bottom: 10px;
  }
}
