.manifest-tree-asset {
  &__dep {
    display: block;
    margin-top: 8px;
  }

  &__remove {
    align-self: center;
  }
}
