@use "variables" as *;

$op-base: 1.3;

@keyframes shimmer {
  0% {
    opacity: $op-base * 0.25;
  }

  12% {
    opacity: $op-base * 0.47;
  }

  32% {
    opacity: $op-base * 0.32;
  }

  46% {
    opacity: $op-base * 0.51;
  }

  60% {
    opacity: $op-base * 0.3;
  }

  75% {
    opacity: $op-base * 0.47;
  }

  83% {
    opacity: $op-base * 0.3;
  }

  95% {
    opacity: $op-base * 0.45;
  }

  100% {
    opacity: $op-base * 0.25;
  }
}
