@use "variables" as *;
@use "mixins" as *;

.markdown {
  @include break-word;

  img {
    max-width: 100%;
  }

  h1,
  h2,
  h3 {
    font-weight: 700;
    margin-top: 0;
  }

  h1,
  h2,
  h3,
  code,
  p,
  ul,
  ol,
  ul li,
  ol li {
    margin-bottom: 8px;
  }

  h1 {
    font-size: 20px;
    line-height: 1.4;
  }

  h2 {
    font-size: 18px;
    line-height: 1.5;
  }

  h3 {
    font-size: 16px;
    line-height: 1.5;
  }

  p {
    font-weight: 400;
  }

  blockquote {
    border-left: 4px solid $border-subtle;
    padding: 0 0 0 15px;
  }

  ul,
  ol {
    padding-left: 20px;

    > li:last-child {
      margin-bottom: 0;
    }
  }

  li {
    font-weight: 400;
  }

  code {
    background: $surface-alt;
    border-radius: 2px;
    color: $text-primary;
    font-family: "Source Code Pro", monospace;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    padding: 2px 4px;
  }

  pre {
    background: $backdrop-default;
    border: none;
    border-radius: 2px;
    display: block;
    line-height: 1.8;
    padding: 15px;

    code {
      background: none;
    }
  }

  > *:last-child {
    margin-bottom: 0;
  }
}
