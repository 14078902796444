@use "sass:math" as math;

@mixin stripe-pattern-bg($stripe-width, $color, $bg, $gap-width: $stripe-width) {
  // To get x-axis gradient stop for the given stripe-width we must divide it by sqrt(2), as it has an angle of 45deg.
  $stripe-start: math.div($stripe-width, 1.414);
  $gap-start: $stripe-start + math.div($gap-width, 1.414);
  $gradient:
    135deg,
    $bg,
    $bg $stripe-start,
    $color $stripe-start,
    $color $gap-start;

  background: repeating-linear-gradient(#{$gradient});
}
