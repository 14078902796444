@use "variables" as *;
@use "mixins" as *;

.facets-filter {
  background: $surface-default;
  border: 1px solid $border-subtle;
  border-radius: 2px;
  padding: 24px;

  &__section {
    margin-bottom: 25px;

    &:last-child {
      margin: 0;
    }
  }

  &__section-title {
    color: $text-secondary;
    font-size: 14px;
    font-weight: 700;
    margin: 0 0 10px;
    text-transform: uppercase;
  }

  &__section-list {
    @include reset-list;

    margin-bottom: 5px;

    &:last-child {
      margin: 0;
    }

    &_short {
      // hides 6 and beyond items
      .facets-filter__section-item:nth-child(n + 6) {
        display: none;
      }
    }

    .facets-filter_menu & {
      margin-top: 20px;
    }
  }

  &__section-item {
    &_selected {
      /* stylelint-disable-next-line declaration-no-important */
      display: block !important;
    }

    .facets-filter_menu & {
      margin-bottom: 15px;
      overflow: hidden;

      &:last-child {
        margin: 0;
      }
    }
  }

  &__section-label {
    align-items: flex-start;
    cursor: pointer;
    display: flex;
    font-weight: 400;
  }

  &__category {
    @include reset-button;

    color: $link-secondary-color;
    cursor: pointer;
    display: block;
    margin-top: 8px;

    &:focus,
    &:hover {
      color: $link-secondary-hover-color;
    }

    &_active {
      &,
      &:focus,
      &:hover {
        color: $link-accent-color;
      }
    }
  }

  &__section-checkbox {
    &[type="checkbox"] {
      margin: 4px 5px 0 0;
    }

    .facets-filter_menu & {
      left: -50px;
      position: absolute;
    }
  }

  &__section-text {
    overflow: hidden;
    word-wrap: break-word;

    .facets-filter_menu & {
      color: $text-secondary;
      font-weight: 700;
    }
  }

  &__more {
    @include button-pseudo-link($link-primary-color, $link-primary-hover-color);
  }

  &_menu &__section-checkbox:checked + &__section-text {
    color: $accent-fg;
  }
}
