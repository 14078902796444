@use "variables" as *;

.section-title {
  align-items: center;
  color: $text-secondary;
  display: flex;
  font-size: 12px;
  font-weight: 700;
  margin: 0 0 20px;
  text-transform: uppercase;
  white-space: nowrap;

  &_with-actions {
    margin-bottom: 16px;
  }

  &_with-paging-count {
    font-weight: inherit;
    text-transform: inherit;
  }

  &::after {
    background: $border-subtle;
    content: "";
    flex: 1;
    height: 1px;
    margin: 0 12px;
    order: 1;

    &:last-child {
      margin-right: 0;
    }
  }

  &__action {
    font-weight: 400;
    order: 2;
    text-transform: none;
  }
}
