@use "variables" as *;
@use "mixins" as *;

.install-popup {
  background: $dialog-header-background;
  border: 1px solid $border-default;
  border-radius: $border-radius-l;
  box-shadow: $popup-shadow;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 416px;
  overflow: hidden;

  &__image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 100px;
    margin: 0 auto 24px;
    width: 250px;

    &_prepare {
      @include background-image-theme("empty/preparing", "svg", true);
    }

    &_test {
      @include background-image-theme("empty/test", "svg", true);
    }

    &_custom {
      @include background-image-theme("empty/community", "svg", true);
    }

    @include device(phablet) {
      height: 80px;
      width: 200px;
    }
  }

  &__title {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    text-align: center;

    @include device(phablet) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  &__text {
    font-size: 14px;
    line-height: 20px;
    margin-top: 4px;
    text-align: center;
  }

  &__action {
    margin-top: 24px;
    text-align: center;

    @include device(phablet) {
      margin-top: 16px;
    }
  }

  &__subaction {
    margin-top: 10px;
  }

  &__subaction-button {
    @include button-pseudo-link(
      $color: $link-neutral-color,
      $hover-color: $link-neutral-hover-color,
      $text-decoration: "hover"
    );

    font-size: 11px;
  }

  &__progress {
    padding: 17px 70px 25px;
  }

  &__progress-amount {
    color: $text-secondary;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
  }
}
