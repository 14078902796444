@use "sass:math";
@use "variables" as *;

@mixin codemirror-disabled(
  $background: $form-disabled-background,
  $color: $form-disabled-text-color,
  $cursor: not-allowed
) {
  .CodeMirror,
  .CodeMirror-gutters {
    /* stylelint-disable-next-line declaration-no-important */
    background-color: $background !important;
    /* stylelint-disable-next-line declaration-no-important */
    color: $text-secondary !important;
  }

  .CodeMirror-gutters,
  .CodeMirror-linenumber,
  .CodeMirror-guttermarker-subtle {
    /* stylelint-disable-next-line declaration-no-important */
    color: $color !important;
  }

  .CodeMirror-lines,
  .CodeMirror-line {
    cursor: $cursor;
  }

  .CodeMirror-vscrollbar {
    cursor: default;
  }
}

@mixin codemirror-selection-color($color) {
  .CodeMirror-selected,
  .CodeMirror-line::selection,
  .CodeMirror-line > span::selection,
  .CodeMirror-line > span > span::selection {
    /* overrides vendor codemirror styles for selection */
    /* stylelint-disable-next-line declaration-no-important */
    background: $color !important;
  }
}

@mixin codemirror-code-mode() {
  .CodeMirror-line {
    // Overrides vendor codemirror styles for word breaking.
    // By default `CodeMirror` uses word wrapping strategy (`word-wrap: word-break`) which
    // is not convenient for code editors such as formulas, schemas and so on.
    /* stylelint-disable-next-line declaration-no-important */
    word-break: break-all !important;
    /* stylelint-disable-next-line declaration-no-important */
    word-wrap: normal !important;
  }
}

@mixin codemirror-height($height, $line-height) {
  $gap: math.div($height - $line-height, 2);

  .CodeMirror {
    min-height: $height;
  }

  .CodeMirror-lines {
    min-height: $height;
    padding: $gap 0;
  }

  .CodeMirror-line {
    cursor: text;
    min-height: $line-height;
  }

  .CodeMirror-line + .CodeMirror-line {
    min-height: $line-height + math.div($gap, 2);
    padding-top: math.div($gap, 2);
  }
}
