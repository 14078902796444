@use "variables" as *;
@use "mixins" as *;

.api-header {
  align-items: baseline;
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;

  @include device(phone) {
    display: block;
    margin-bottom: 20px;
  }

  &_partner {
    border-bottom: 1px solid $border-subtle;
    margin-bottom: 30px;
    padding-bottom: 30px;
  }

  &_columns {
    justify-content: flex-start;
  }

  &__title {
    color: $text-secondary;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.4;
    margin: 0;
  }

  &__label {
    background: $surface-alt;
    border-radius: 10px;
    font-size: 12px;
    margin-left: 16px;
    padding: 2px 8px;
  }

  &__label-close {
    @include reset-button;

    color: $icon-default;
    cursor: pointer;
    margin-right: 4px;
    vertical-align: middle;
  }
}
