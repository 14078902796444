@use "variables" as *;
@use "mixins/reset-button" as *;
@use "mixins/forced-scrollbar" as *;
@use "mixins/fullscreen-hidden" as *;

$content-margin-right: 8px;

@mixin without-nav {
  .hidden-header &,
  .widget-page &,
  .fullscreen-page-root & {
    @content;
  }
}

@mixin gradient-background($env-color) {
  background: linear-gradient(0deg, $surface-inverse-opacity-5 0%, $surface-inverse-opacity-5 100%),
    linear-gradient(201.22deg, $env-color -4.03%, $sidebar-main 46.18%);
}

@mixin nav-separator {
  background: radial-gradient(circle, $sidebar-fg-secondary 0%, transparent 100%);
  border: none;
  border-radius: $border-radius-xs;
  flex-shrink: 0;
  height: 1px;
  margin: 0;
  opacity: 0.3;
}

@mixin nav-header-dropdown-trigger($padding: 4px) {
  @include reset-button;

  align-items: center;
  background: linear-gradient(
    180deg,
    var(--app-header-bg-start-color, $sidebar-submenu-opacity-30) 0%,
    var(--app-header-bg-end-color, $sidebar-submenu-opacity-70) 100%
  );
  border-radius: $border-radius-s;
  display: flex;
  gap: 6px;
  min-width: 0;
  padding: $padding;
  transition-duration: $nav-transition-duration;
  transition-property: --app-header-bg-start-color, --app-header-bg-end-color, box-shadow;
  transition-timing-function: $w-easy-ease;

  &:hover:not(:disabled) {
    --app-header-bg-start-color: #{$sidebar-submenu-opacity-30};
    --app-header-bg-end-color: #{$sidebar-submenu-opacity-70};
    cursor: pointer;
  }

  &:active:not(:disabled),
  &.overlay-active {
    --app-header-bg-start-color: #{$sidebar-submenu-opacity-30};
    --app-header-bg-end-color: #{$sidebar-submenu-opacity-70};
    border: $nav-border;
    box-shadow: $nav-header-shadow;
    padding: $padding - 1px;
  }
}

@mixin app-layout {
  display: grid;
  grid-template:
    "header header header" auto
    "sidebar-l content content" 1fr
    "sidebar-l footer footer" auto /
    $sidebar-collapsed-width 1fr auto;
  height: 100vh;

  &_mobile {
    grid-template:
      "header header header header" auto
      "sidebar-l content content sidebar-r" 1fr
      "sidebar-l footer footer sidebar-r" auto /
      $sidebar-collapsed-width-mobile 1fr auto 0;
  }

  &_non-env {
    @include gradient-background($backdrop-non-env);
  }

  &_dev {
    @include gradient-background($backdrop-dev);
  }

  &_test {
    @include gradient-background($backdrop-test);
  }

  &_prod {
    @include gradient-background($backdrop-prod);
  }

  @include without-nav {
    grid-template: "content" 1fr "footer" auto / 1fr;
  }

  &__header {
    grid-area: header;
    height: $header-bar-height + $header-inner-height;
    margin-bottom: $header-bottom-margin;
    z-index: $header-z-index;

    @include without-nav {
      display: none;
    }

    @include fullscreen-hidden;
  }

  &__sidebar {
    grid-area: sidebar-l;
    position: relative;
    z-index: $header-z-index + 1;

    @include without-nav {
      display: none;
    }

    @include fullscreen-hidden;
  }

  &__mobile-sidebar {
    grid-area: sidebar-r;
    position: relative;
    z-index: $header-z-index + 1;

    @include without-nav {
      display: none;
    }

    @include fullscreen-hidden;
  }

  &__content {
    @include forced-scrollbar;

    background-color: $backdrop-default;
    border-radius: $border-radius-l $border-radius-l 0 0;
    grid-area: content;
    margin-right: $content-margin-right;
    overflow: auto;
    position: relative;

    @include without-nav {
      border-radius: 0;
      margin-right: 0;
    }
  }

  &__banners {
    display: block;
    position: sticky;
    top: 0;
    z-index: $header-z-index - 1;

    @include fullscreen-hidden;
  }

  &__footer {
    background-color: $backdrop-default;
    display: flex;
    grid-area: footer;
    margin-right: $content-margin-right;

    @include without-nav {
      margin-right: 0;
    }

    @include fullscreen-hidden;
  }

  &__overlay {
    backdrop-filter: blur(10px);
    background: $sidebar-submenu-opacity-70;
    content: "";
    inset: $header-bar-height 0 0 0;
    position: fixed;
    z-index: $header-z-index;
  }

  &__widget-copyright {
    display: inline-block;
    margin: $widget-copyright-space $widget-copyright-space $widget-copyright-space auto;
  }
}
