@use "variables" as *;
@use "mixins" as *;
@use "logo" as *;
@use "burger-menu" as *;

.header {
  background: $neutral-dark;
  display: block;
  font-family: $primary-font-family;
  font-size: $default-font-size;
  left: 0;
  line-height: 1.4;
  min-width: 320px;
  user-select: none;

  @include device(compact) {
    &::after {
      /* stylelint-disable-next-line color-no-hex -- This color should not change with the theme */
      background: #000;
      content: "";
      height: 0;
      left: 0;
      opacity: 0;
      pointer-events: none;
      position: fixed;
      right: 0;
      top: 0;
      transition: opacity 0.15s ease-out;
    }
  }

  &_user-open {
    @include device(compact) {
      .header-item:not(.header-item_user) {
        display: none;
      }

      .header__separator {
        display: none;
      }
    }
  }

  &_theme_light {
    background: $surface-default;
    border-bottom: 1px solid $border-subtle;

    .logo {
      @extend .logo_guest;
    }

    .burger-menu {
      @extend .burger-menu_guest;
    }
  }

  &__top {
    @include device(compact) {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: space-between;
      width: 100%;
    }
  }

  &__separator {
    display: none;

    @include device(compact) {
      background: $accent-emphasis;
      display: block;
      height: 1px;
      margin: 15px 40px;
      opacity: 0.2;
    }
  }

  &__content {
    @include container;

    align-items: center;
    display: flex;
    height: $guest-header-height;
    position: relative;

    @include device(compact) {
      display: block;
    }
  }

  &__nav {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 1 1 auto;
    justify-content: space-between;

    @include device(compact) {
      align-items: flex-start;
      background: $surface-default;
      flex-direction: column;
      height: 100vh;
      overflow: hidden;
      padding-bottom: 40px;
      position: absolute;
      right: -$guest-header-menu-push-tablet;
      top: 0;
      transition: opacity 250ms ease-out;
      width: 0;

      &::after {
        content: "";
        flex: 1 1 auto;
        order: 10;
        width: 100%;
      }

      &_open {
        /* stylelint-disable-next-line function-disallowed-list -- This color should not change with the theme */
        box-shadow: -15px 0 20px rgb(0 0 0 / 20%);
        display: flex;
        overflow: auto;
        width: $guest-header-menu-push-tablet;
      }
    }

    @include device(phone) {
      right: -$guest-header-menu-push-phone;

      &_open {
        width: $guest-header-menu-push-phone;
      }
    }
  }

  &__bars-menu {
    color: $accent-fg;
    cursor: pointer;
    display: none;
    font-size: 25px;

    &:focus,
    &:active,
    &:hover {
      color: $text-primary;
    }

    @include device(compact) {
      display: block;
    }
  }
}
