@use "shared/mixins/map-get-strict" as *;
@use "shared/mixins/wrap-with-root" as *;

@mixin base($settings) {
  @include wrap-with-root {
    --page-breadcrumbs-chevron-width: #{map_get_strict($settings, "chevron-width")};
    --page-breadcrumbs-chevron-color: #{map_get_strict($settings, "chevron-color")};
    --page-breadcrumbs-item-color: #{map_get_strict($settings, "item-color")};
    --page-breadcrumbs-inactive-item-color: #{map_get_strict($settings, "inactive-item-color")};
    --page-breadcrumbs-delimeter-width: #{map_get_strict($settings, "delimiter-width")};
  }
}
