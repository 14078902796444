@use "variables" as *;
@use "mixins" as *;

.w-page-wrapper {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;

  @media (max-width: $page-compact-view) {
    transition: transform 0.15s ease-out;

    .w-page-global_pushed & {
      transform: translateX(-$guest-header-menu-push-tablet);
    }
  }

  @include device(phone) {
    .w-page-global_pushed & {
      transform: translateX(-$guest-header-menu-push-phone);
    }
  }

  &__container {
    position: relative;
  }
}
