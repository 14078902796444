@use "mixins" as *;

.header-items {
  align-items: center;
  display: flex;
  height: 100%;
  list-style: none;
  margin: 0;
  padding: 0;

  &_right {
    margin-left: auto;
  }

  @include device(compact) {
    align-self: flex-start;
    display: block;
    flex: 0 0 auto;
    height: auto;
    padding: 10px 0;
    position: relative;
    width: 100%;

    &_right {
      display: none;
    }

    &_user {
      display: flex;
      flex-direction: column;
      order: -1;
      padding: 0;
    }
  }
}
