.people-task-table {
  text-align: left;
  width: 100%;

  td,
  th {
    border: none;
    padding: 0 10px 10px 0;
    vertical-align: top;
  }
}
