@use "variables" as *;

.api-client-logo {
  border: 1px solid $border-subtle;
  border-radius: 2px;
  height: 60px;
  width: 60px;

  &_small {
    height: 32px;
    width: 32px;
  }

  &_popup {
    height: 100%;
    width: 100%;
  }
}
