@use "variables" as *;

@mixin drag-handle($handle-color: $accent-default) {
  @include drag-handle-cursor;

  background: transparent;
  border: none;
  border-left: 2px solid;
  border-right: 2px solid;
  box-sizing: border-box;
  height: 16px;
  left: -4px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 7px;
  // Needs to be after border styles
  @include drag-handle-color($handle-color);
}

@mixin drag-handle-color($color) {
  border-image: repeating-linear-gradient(to bottom, $color 0 2px, transparent 2px 4px) 2 / 0 2px repeat;
}

@mixin drag-handle-cursor {
  cursor: image-url("closedhand.cur"), move;
}
