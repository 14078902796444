@use "variables" as *;
@use "mixins" as *;

.tree {
  &__item {
    display: block;
  }

  &__item-toggle {
    @include reset-button;

    color: $neutral-muted;
    display: inline-block;
    margin-right: 3px;
    user-select: none;
    visibility: hidden;
    width: 10px;

    &_visible {
      cursor: pointer;
      visibility: visible;
    }

    &_open {
      transform: rotate(90deg);
    }
  }
}
