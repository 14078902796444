/* List of all non-standard colors */
/* stylelint-disable color-no-hex, function-disallowed-list -- These colors do not change with the theme */

$formula-suggestion-autocomplete-color: #e4e8eb; // $N050
$formula-suggestion-autocomplete-highlight: #476270; // $N400
$formula-suggestion-autocomplete-background: #38444a;
$repeat-icon-background-color: #e4e8eb;
$repeat-icon-border-color: #bac4cc;
$catch-icon-color: #108291; // $T400
$rating-star-color: #ffee46;
$auth-logo-color: #fff;
$scrollbar-color: rgb(186 196 204 / 40%); // $N100 @40%
$scrollbar-hover-color: rgb(186 196 204 / 80%); // $N100 @80%

$quickbooks-button-color: #2c9f1c;
$microsoft-button-color: #2f2f2f;
$google-button-color: #4285f4;

$pill-dark-color: #fff;
$pill-dark-border: #476270; // $N400
$pill-dark-background: #0a1b22; // $N700
$pill-dark-secondary-color: #e4e8eb; // $N050
$pill-dark-error-divider: #b6270a; // $R500
$pill-dark-error-background: #350b02; // $R800
$pill-dark-error-background-emphasis: #5b1203; // $R600
$pill-dark-error-border: #b6270a; // $R500
$pill-dark-error-border-emphasis: #f4a293; // $R200;

$mktg-black: #111010; // $BR-N400
$mktg-button-disabled-background: #f5f6f8; // $BR-N025
$mktg-button-disabled-color: #686868; // $BR-N200
$mktg-button-primary-pending-background: #eaf7f8; // $BR-T010
$mktg-button-neutral-pending-background: #bdbdbd;

$mdn-like-theme-comment-color: #aaa;
$material-theme-text-color: #b7b7b7;
$material-theme-background: #090d0e; // $N900-D
$material-theme-selection-color: rgb(102 235 221 / 30%);

$custom-adapter-details-tab-recipe-bg-light-1: #e5e3eb; // $SL050
$custom-adapter-details-tab-recipe-bg-light-2: #dde7e2; // $FR050
$custom-adapter-details-tab-recipe-bg-light-3: #e4e8eb; // $N050
$custom-adapter-details-tab-recipe-bg-dark-1: #26212b; // $SL800
$custom-adapter-details-tab-recipe-bg-dark-2: #063338; // $T700
$custom-adapter-details-tab-recipe-bg-dark-3: #282525; // $BR-N300
$custom-adapter-details-tab-recipe-border-light-1: #fbdcdd; // $BR-P050
$custom-adapter-details-tab-recipe-border-light-2: #b3fef7; // $BR-T050
$custom-adapter-details-tab-recipe-border-light-3: #afc1ff; // $BR-B050
$custom-adapter-details-tab-recipe-border-dark-1: #f3c1c2; // $BR-P100
$custom-adapter-details-tab-recipe-border-dark-2: #67eadd; // $BR-T100
$custom-adapter-details-tab-recipe-border-dark-3: #5159f6; // $BR-B100

$tooltip-background-color: #04141a; // $N800
$tooltip-outline-color: rgb(255 255 255 / 20%); // $N00 @20%
$tooltip-border-color: rgb(255 255 255 / 30%); // $N00 @30%
$tooltip-text-color: #fff; // $N00

$aws-secrets-manager-background: linear-gradient(45deg, #bd0816 -4950%, #ff5252 5050%);

/* stylelint-enable color-no-hex, function-disallowed-list */
