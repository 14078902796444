.browse-page {
  &__title {
    font-size: 16px;
    font-weight: 700;
    margin: 32px 0 16px;
  }

  &__section {
    margin-bottom: 32px;

    &:last-child {
      margin-bottom: 0;
    }

    &_no-title {
      margin-top: 32px;
    }
  }

  &__section-title.section-title {
    margin: 36px 0 32px;
  }

  &__section-external-link {
    font-size: 12px;
  }

  &__adapters-groups {
    .adapter-list-groups {
      &__group-title.section-title {
        margin: 36px 0 32px;
      }
    }
  }
}
