@use "theme" as *;
@use "shadows" as *;

$card-background: $surface-default;
$card-hover-background: $surface-elevated;
$card-empty-background: $surface-opacity-50;

$card-label-color: $neutral-fg-emphasis;
$card-title-color: $text-primary;
$card-description-color: $text-secondary;
$card-placeholder-color: $text-placeholder;

$card-inner-border: $border-subtle;
$card-border: $border-default;
$avatar-border: $border-inverse;

$card-resting-shadow: $elevation-1;
$card-hover-shadow: $elevation-4_neutral;

$card-min-height: 186px;
