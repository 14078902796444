@use "variables" as *;
@use "mixins" as *;

.resource-icon {
  align-items: center;
  background-color: $resource-default-background-color;
  border-radius: $border-radius-s;
  color: $resource-default-color;
  display: flex;
  height: 28px;
  justify-content: center;
  width: 28px;

  &_full-height {
    height: 100%;
  }

  &_full-height-with-padding {
    align-items: flex-start;
    height: 100%;
  }

  &_rounded {
    border-radius: $border-radius-m;
  }

  @include resource-type-background;
}
