@use "variables" as *;

.people-task-form {
  .w-form-group {
    margin-bottom: 30px;
  }

  .w-form-label__text {
    color: $text-primary;
    font-weight: 600;
    line-height: 1;
    margin-bottom: 10px;
  }

  .w-form-message {
    color: $text-secondary;
    margin-top: 6px;
  }

  &__actions {
    margin-bottom: 35px;
    margin-top: 45px;
  }
}
