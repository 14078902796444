@use "sass:math" as math;
@use "theme" as *;
@use "shadows" as *;
@use "misc" as *;

$recipe-background: $neutral-tint;
$recipe-secondary-background: $surface-subtle;
$recipe-canvas-background-color: $surface-field;
$recipe-step-background: $neutral-tint;
$recipe-step-title-color: $text-primary;
$recipe-step-border-color: $border-subtle;
$recipe-arrow-color: $neutral-border;
$recipe-arrow-text-color: $recipe-step-title-color;
$recipe-selected-arrow-label: $accent-fg;

$recipe-canvas-transition: transform ease-in-out 250ms;
$recipe-steps-padding: 37px;
$recipe-steps-padding-phone: 20px;
$recipe-step-header-padding: 7px;
$recipe-step-indent-width: 70px;
$recipe-step-indent-width-phone: 16px;
$recipe-step-transition-timing: 0.3s ease;
$recipe-steps-title-height: 29px;
$recipe-steps-title-bottom-margin: 8px;
$recipe-arrow-pointer-size: 10px;
$recipe-arrow-half-pointer-size: math.div($recipe-arrow-pointer-size, 2);
$recipe-arrow-line-width: 1px;
$recipe-arrow-margin: 24px;
$recipe-arrow-margin-phone: 8px;
$recipe-repeat-arrow-bottom-padding: 19px;
$recipe-common-arrow-height: 40px;
$recipe-subtree-arrow-width: $recipe-step-indent-width + 2;
$recipe-subtree-arrow-width-phone: $recipe-step-indent-width-phone + 2;
$recipe-common-arrow-height-with-gap: 60px;
$recipe-common-arrow-gap-height: $recipe-common-arrow-height-with-gap - $recipe-common-arrow-height;
$recipe-subtree-arrow-height: 60px;
$recipe-subtree-arrow-height-compact: 44px;
$recipe-canvas-vertical-indent: 28px;
$recipe-canvas-horizontal-indent: 24px;
$recipe-step-width: 482px;
$recipe-step-border-radius: $border-radius-m;
$recipe-step-icon-size: 32px;
$recipe-condition-operand-width: 40px;
$recipe-stop-icon-radius: 4px;

$recipe-static-list-field-item-gap: 40px;
$recipe-template-width: 84px;
$recipe-template-icon-size: 56px;

$recipe-step-stripe-width: 8px;
$recipe-step-condition-vertical-padding: 24px;
$recipe-step-condition-horizontal-padding: 16px;

$step-hover-shadow: $elevation-1;
$step-error-hover-shadow: $elevation-1;
$step-selected-shadow:
  0 0 0 1px $accent-border,
  $elevation-4_teal;
$step-error-selected-shadow:
  0 0 0 1px $error-border-emphasis,
  $elevation-4_red;
$step-test-alt-selected-shadow:
  0 0 0 1px $indigo-border,
  $elevation-4_indigo;
