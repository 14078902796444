@use "variables" as *;

.editable-text-popup-target {
  border-radius: $border-radius-xs;
}

.editable-text-popup-target.editable-text-popup-target_editing {
  background: $surface-subtle;
  box-shadow: 0 0 0 4px $surface-subtle;
  color: $text-disabled;
}
