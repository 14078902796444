@use "variables" as *;
@use "mixins" as *;

.auth-content {
  $selector: &;
  $font-color: $text-secondary;
  $auth-width: 600px;
  $box-border-radius: 10px;

  background: $surface-default;
  border-radius: $box-border-radius;
  margin: 0 auto;
  max-width: 100%;
  position: relative;
  width: $auth-width;

  &_with-quote {
    @include device-min(desktop-hd) {
      flex: 0 0 auto;
      margin: 0;
    }
  }

  &__header {
    margin: 0 0 32px;
  }

  &__image {
    height: 120px;
    margin: 0 auto 24px;
    max-width: 100%;
    width: 300px;
  }

  &__title {
    font-size: 28px;
    font-weight: 700;
    line-height: 1.2;
    margin: 0;
    text-align: center;

    @include device(phablet) {
      font-size: 22px;
    }

    #{$selector}_with-image & {
      font-size: 24px;
      line-height: 1.33;
    }
  }

  &__subtitle {
    font-size: 20px;
    margin: 16px 0 0;
    text-align: center;

    @include device(phone) {
      font-size: 14px;
    }

    &:first-child {
      margin-top: 0;
    }

    #{$selector}_with-image & {
      font-size: 16px;
      line-height: 1.5;
      margin: 24px 0 0;
    }
  }

  &__main {
    margin: 0 auto;
    padding: 80px;
    transition: opacity ease 0.2s;

    a:not(.button) {
      color: inherit;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }

    @include device(phone) {
      padding: 40px 15px;
    }

    &_hidden {
      opacity: 0;
    }
  }

  &__info {
    font-size: 20px;
    margin-top: 16px;
    text-align: center;

    @include device(phone) {
      font-size: 14px;
    }

    #{$selector}_with-image & {
      font-size: 16px;
      line-height: 1.5;
      margin-top: 24px;
    }
  }

  &__form {
    display: block;

    &_no-margin {
      margin-top: 0;
    }
  }

  &__footer {
    background: $surface-subtle;
    border-radius: 0 0 $box-border-radius $box-border-radius;
    margin-top: -40px;
    padding: 0 40px;

    @include device(phone) {
      margin-top: -10px;
      padding: 0 15px;
    }
  }

  &__apps {
    padding: 16px 0 40px;
  }

  &__apps-title {
    align-items: center;
    color: $text-primary;
    display: flex;
    font-size: 12px;
    justify-content: space-between;
    margin-bottom: 16px;

    &::before,
    &::after {
      border-bottom: 1px solid $border-subtle;
      content: "";
      display: block;
      flex: 1 1 auto;
    }
  }

  &__apps-label {
    flex: 0 0 auto;
    margin: 0 15px;
  }

  &__footer-checkbox-label {
    align-items: center;
    display: flex;
    font-weight: 400;
    justify-content: center;
  }

  &__footer-checkbox-label &__footer-checkbox {
    margin: 0 8px 0 0;
  }

  &__link {
    @include reset-button;

    cursor: pointer;
    text-decoration: underline;

    &:hover,
    &:focus {
      text-decoration: none;
    }

    &_primary {
      color: $link-primary-color;

      &:hover,
      &:focus {
        color: $link-primary-hover-color;
      }
    }
  }

  &__spinner {
    align-items: center;
    display: flex;
    height: 100%;
    inset: 0;
    justify-content: center;
    position: absolute;
    width: 100%;
  }
}
