@use "variables" as *;

.operation-picker-empty {
  line-height: 1.5;
  padding: 12px;
  text-align: center;

  &__message {
    border-bottom: 1px solid $border-subtle;
    margin-bottom: 20px;
    padding-bottom: 16px;
  }

  &__title {
    font-size: 16px;
    font-weight: 700;
    margin: 0 0 8px;
  }

  &__description {
    color: $text-secondary;
    margin: 0;
  }
}
