@use "variables" as *;
@use "color-overlay" as *;

@mixin pill {
  align-items: stretch;
  border-radius: $pill-border-radius;
  cursor: default;
  display: inline-flex;
  font-family: $primary-font-family;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  position: relative;
  vertical-align: middle;
  white-space: nowrap;
}

@mixin pill-icon($size) {
  border-radius: $pill-border-radius 0 0 $pill-border-radius;
  height: $size;
  overflow: hidden;
  width: $size;
}

@mixin pill-info($padding) {
  align-items: center;
  border-radius: 0 $pill-border-radius $pill-border-radius 0;
  border-style: solid;
  border-width: 1px 1px 1px 0;
  display: inline-flex;
  padding: $padding;
  z-index: 1;
}

@mixin pill-name {
  font-size: $pill-name-font-size;
  font-weight: $pill-name-font-weight;
  margin-left: 8px;
}

@mixin pill-note($margin-left, $padding-left) {
  border-color: inherit;
  border-left-style: solid;
  border-left-width: 1px;
  margin-left: $margin-left;
  padding-left: $padding-left;
}

@mixin pill-palette(
  $selector,
  $background,
  $border-color,
  $color,
  $note-color: $color,
  $divider-color: $border-color,
  $override-icon-border: false,
  $gradient: false
) {
  color: $color;

  @if $gradient {
    position: relative;

    &::before {
      background:
        $background padding-box,
        $border-color border-box;
      border: 1px solid transparent;
      border-radius: inherit;
      content: "";
      inset: 0;
      margin-right: -1px;
      position: absolute;
    }
  } @else {
    background: $background;
    border-color: $border-color;
  }

  #{$selector}__name {
    color: $color;
  }

  #{$selector}__note {
    border-color: $divider-color;
    color: $note-color;
  }

  #{$selector}__info {
    @if $gradient {
      border: none;
    } @else {
      border-color: $border-color;
    }
  }

  #{$selector}__svg-icon {
    border-color: $border-color;
  }

  @if $override-icon-border == true {
    #{$selector}__info {
      /* Overlap app-icon right border with #{$selector}__info border to remove gap between them */
      margin-left: -1px;
      padding-left: 1px;
    }

    @if $gradient {
      .app-icon {
        border-bottom: 1px solid transparent;
        border-left: 1px solid transparent;
        border-top: 1px solid transparent;
      }
    }

    .app-icon__icon {
      /* stylelint-disable declaration-no-important */
      @if $gradient {
        border-bottom: none !important;
        border-left: none !important;
        border-radius: 1px 0 0 1px;
        border-top: none !important;
        padding: 1px;
        position: relative;

        &::before {
          border-radius: inherit;
          border-right: 1px solid transparent;
          border-right-color: inherit !important;
          content: "";
          inset: 0;
          margin-right: -1px;
          position: absolute;
        }
      } @else {
        border: 1px solid;

        border-bottom-color: $border-color !important;
        border-left-color: $border-color !important;
        border-right: none;
        border-top-color: $border-color !important;
        /* stylelint-enable */
      }
    }
  }
}

@mixin pill-invalid-icon($selector, $icon-path, $width: 10px) {
  #{$selector}__note::before {
    content: image-url($icon-path);
    display: inline-block;
    height: 10px;
    margin-right: 3px;
    position: relative;
    top: 1px;
    width: $width;
  }
}

@mixin pill-light-theme($selector) {
  @include pill-palette($selector, $neutral-tint, $border-default, $text-primary, $text-secondary);

  &#{$selector}_hover {
    @include pill-palette(
      $selector,
      $pill-hovered-background,
      $pill-hovered-border-color,
      $pill-hovered-color,
      $text-secondary
    );
  }

  &#{$selector}_invalid {
    @include pill-palette(
      $selector,
      $surface-default,
      $error-border,
      $error-fg,
      $divider-color: $error-border,
      $override-icon-border: true
    );
    @include pill-invalid-icon($selector, "exclamation-rhomb-red.svg");
  }

  &#{$selector}_invalid#{$selector}_hover {
    @include pill-palette(
      $selector,
      $surface-default,
      $error-border-emphasis,
      $error-fg-emphasis,
      $divider-color: $error-border,
      $override-icon-border: true
    );
  }

  &#{$selector}_invalid#{$selector}_selected {
    @include pill-palette(
      $selector,
      $error-tint,
      $error-border-emphasis,
      $error-fg-emphasis,
      $divider-color: $error-border,
      $override-icon-border: true
    );
  }
}

@mixin pill-dark-theme($selector) {
  @include pill-palette(
    $selector,
    $pill-dark-background,
    $pill-dark-border,
    $pill-dark-color,
    $pill-dark-secondary-color,
    $override-icon-border: true
  );

  &#{$selector}_hover {
    @include pill-palette(
      $selector,
      $pill-dark-background,
      $accent-border,
      $pill-dark-color,
      $pill-dark-secondary-color,
      $override-icon-border: true
    );
  }

  &#{$selector}_invalid {
    @include pill-palette(
      $selector,
      $pill-dark-error-background,
      $pill-dark-error-border,
      $pill-dark-color,
      $divider-color: $pill-dark-error-divider,
      $override-icon-border: true
    );
    @include pill-invalid-icon($selector, "exclamation-rhomb-light-red.svg");
  }

  &#{$selector}_invalid.pill_hover {
    @include pill-palette(
      $selector,
      $pill-dark-error-background,
      $pill-dark-error-border-emphasis,
      $pill-dark-color,
      $divider-color: $pill-dark-error-divider,
      $override-icon-border: true
    );
  }

  &#{$selector}_invalid.pill_selected {
    @include pill-palette(
      $selector,
      $pill-dark-error-background-emphasis,
      $pill-dark-error-border-emphasis,
      $pill-dark-color,
      $divider-color: $pill-dark-error-divider,
      $override-icon-border: true
    );
  }
}

@mixin pill-faded-theme($selector) {
  @include pill-palette($selector, $surface-subtle, $border-default, $text-disabled);

  &#{$selector}_hover {
    @include pill-palette($selector, $surface-default, $accent-border-emphasis, $accent-fg, $text-secondary);
  }

  &#{$selector}_invalid {
    @include pill-palette($selector, $surface-subtle, $error-border, $error-fg, $override-icon-border: true);

    opacity: 0.5;
  }

  &#{$selector}_invalid#{$selector}_hover {
    @include pill-palette(
      $selector,
      $surface-subtle,
      $error-border-emphasis,
      $error-fg-emphasis,
      $divider-color: $error-border,
      $override-icon-border: true
    );

    opacity: 1;
  }

  &:not(#{$selector}_invalid, #{$selector}_hover) {
    #{$selector}__note::before {
      opacity: 0.35;
    }

    #{$selector}__icon {
      @include color-overlay($surface-opacity-70);

      position: relative;
    }
  }
}

@mixin pill-suggestion-theme($selector, $highlight-when: hover) {
  @if $highlight-when == always {
    @include pill-palette(
      $selector,
      $pill-suggested-background,
      $accent-border-emphasis,
      $accent-fg,
      $divider-color: $accent-border-emphasis
    );
  }

  &#{$selector}_hover {
    @include pill-palette(
      $selector,
      $pill-suggested-background,
      $accent-border-emphasis,
      $accent-fg,
      $divider-color: $accent-border-emphasis
    );
  }

  &#{$selector}_selected {
    @include pill-palette(
      $selector,
      $ai-chat-primary-gradient,
      $accent-border-emphasis,
      $accent-fg,
      $divider-color: $accent-border-emphasis
    );
  }
}

@mixin pill-suggestion-theme-ai($selector, $highlight-when: hover) {
  @if $highlight-when == always {
    @include pill-palette(
      $selector,
      $pill-suggested-background,
      $pill-suggested-border-color,
      $pill-suggested-color,
      $divider-color: $ai-border,
      $override-icon-border: true,
      $gradient: true
    );
  }

  &#{$selector}_hover {
    @include pill-palette(
      $selector,
      $pill-suggested-background,
      $pill-suggested-border-color,
      $pill-suggested-color,
      $divider-color: $ai-border,
      $override-icon-border: true,
      $gradient: true
    );
  }

  &#{$selector}_selected {
    @include pill-palette(
      $selector,
      $ai-chat-primary-gradient,
      $pill-suggested-border-color,
      $pill-suggested-color,
      $divider-color: $ai-border,
      $override-icon-border: true,
      $gradient: true
    );
  }
}
