@use "variables" as *;

.chart-popover {
  $left-right-content-padding-compensation: -12px;
  $bottom-content-padding-compensation: -12px;

  left: 0;
  padding: 8px;
  pointer-events: none;
  position: absolute;
  top: 0;

  &__content {
    background: $neutral-dark;
    border-radius: $border-radius-m;
    color: $fg-onNeutral-dark;
    font-size: 12px;
    font-weight: 600;
    overflow: hidden;
    padding: 12px;
  }

  &__warning {
    background: $indigo-default;
    color: $fg-onIndigo;
    margin: 12px $left-right-content-padding-compensation $bottom-content-padding-compensation;
    padding: 8px 16px 8px 12px;
    white-space: nowrap;

    &_blue {
      background: $blue-default;
      color: $fg-onBlue;
    }

    &_danger {
      background: $red-default;
      color: $fg-onRed;
    }
  }

  &__header {
    font-weight: 600;
  }

  &__text {
    line-height: 16px;
    white-space: nowrap;

    &_dimmed {
      opacity: 0.5;
    }

    &_with-wrap {
      white-space: normal;
    }
  }

  &__subtext {
    color: $neutral-muted;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }

  &__value {
    font-size: 14px;
    line-height: 20px;
  }

  &__icon {
    border-radius: 50%;
    display: inline-block;
    height: 8px;
    margin-right: 5px;
    width: 8px;
  }

  &__separator {
    background: $text-inverse;
    border: none;
    height: 1px;
    margin: 4px 0;
    opacity: 0.3;

    &_no-border {
      background: transparent;
    }
  }

  &__tags {
    display: grid;
    grid-gap: 4px 8px;
    grid-template-columns: auto 1fr;
    padding: 2px 0 4px;
    white-space: nowrap;
  }

  &__tag {
    display: contents;
    font-weight: 700;
  }

  &__secondary-text {
    color: $neutral-muted;
    font-weight: 400;

    &_nowrap {
      white-space: nowrap;
    }
  }

  &__trend-icon {
    margin: 0 4px -2px;

    &_upward {
      color: $green-default;
    }

    &_downward {
      color: $red-default;
    }
  }
}
