@use "variables" as *;
@use "mixins" as *;

.header-dropdown {
  $background-guest-color: $surface-subtle;
  $header-link-width: 180px;

  background: $surface-default;
  border: 1px solid $border-subtle;
  border-radius: 4px;
  display: block;
  margin: -5px 0 0;
  min-width: 100%;
  position: absolute;

  &_right {
    left: auto;
    right: 0;
  }

  &_center {
    left: 50%;
    transform: translate(-50%, 0);
  }

  @include device(compact) {
    background: $accent-tint;
    border: none;
    border-radius: 0;
    left: auto;
    position: relative;
    top: 0;
    transform: none;
    transition-duration: 0.01ms;

    &_right {
      margin-left: 0;
    }

    .header_theme_light & {
      background: $background-guest-color;
    }
  }

  &__arrow {
    height: 8px;
    left: 24px;
    overflow: hidden;
    position: absolute;
    top: -8px;
    width: 16px;

    &::before {
      background: $surface-default;
      border: 1px solid $border-subtle;
      content: "";
      height: 12px;
      left: 2px;
      position: absolute;
      top: 2px;
      transform: rotate(45deg);
      width: 12px;
    }

    &_right {
      left: auto;
      right: 32px;
    }

    &_center {
      left: 50%;
      transform: translate(-50%, 0);
    }

    .header-dropdown_without-team-settings & {
      &::before {
        background: $accent-tint;
      }
    }

    @include device(compact) {
      &_right,
      &_left,
      &_center {
        left: 46px;
      }

      &::before {
        background: $accent-tint;
        border-color: transparent;

        .header_theme_light & {
          background: $background-guest-color;
        }
      }
    }
  }

  &_columns {
    align-items: stretch;
    display: flex;

    @include device(compact) {
      display: block;
    }
  }

  &__section {
    border-top: 1px solid $border-subtle;
    color: $accent-fg-emphasis;
    padding: 20px;
    white-space: nowrap;

    .header-dropdown_columns & {
      border-left: 1px solid $border-subtle;
      border-top: none;
    }

    &:first-child {
      border: none;
    }

    .header-dropdown_without-team-settings & {
      padding-top: 0;
    }

    @include device(compact) {
      padding: 10px;
    }
  }

  &__title {
    color: $text-secondary;
    font-size: 11px;
    font-weight: 400;
    letter-spacing: 0.5px;
    margin: 0 0 6px;
    padding: 9px 10px;
    text-transform: uppercase;

    @include device(compact) {
      padding: 10px 30px;
      text-align: left;
    }
  }

  &__items {
    @include reset-list;

    &_1-col {
      column-count: 1;
    }

    &_2-col {
      column-count: 2;
    }

    &_3-col {
      column-count: 3;
    }

    @include device(compact) {
      background: none;

      &_1-col,
      &_2-col,
      &_3-col {
        column-count: 1;
      }
    }
  }

  &__item {
    break-inside: avoid;
    color: $text-primary;
    min-width: $header-link-width;
    position: relative;

    &:hover {
      background: $accent-tint;

      @include device(compact) {
        background: none;
      }
    }

    &_only-desktop {
      @include device(compact) {
        display: none;
      }
    }

    &_team {
      margin: 10px -20px;

      &:first-child {
        margin-top: 0;
      }

      &:hover {
        background: none;
      }

      @include device(compact) {
        margin: 0 8px;
      }
    }
  }

  &__all {
    border-top: 1px solid $border-subtle;
    margin: 0 20px;
    padding: 10px 0;
    text-align: center;

    @include device(compact) {
      display: none;
    }

    .header_theme_light & {
      border: none;
      margin: 20px 0 0;
      padding: 0;

      @include device(compact) {
        display: block;
      }
    }
  }
}
