@use "mixins" as *;
@use "shared/theming-mixins/spinner";

.page-loading.simple-page,
.page-loading.w-page-global {
  background-color: var(--page-loading-background-color);

  .page-spinner {
    @include spinner.color(var(--page-loading-spinner-color));
  }
}
