@use "variables" as *;
@use "mixins" as *;
@use "shared/theming-mixins/horizontal-tab-navigation";

$horizontal-tab-navigation-theme: (
  "tab": (
    "padding": 16px,
    "active-color": $tab-active-color,
    "inactive-color": $tab-inactive-color,
    "content-max-width": 280px,
    "bar-height": 3px,
    "bar-hover-color": $neutral-border,
    "active-bar-background": $tab-bar-active-background,
    "font-size": 16px,
  ),
  "container": (
    "box-shadow": $border-default,
    "padding-left": $container-padding-left,
    "padding-right": $container-padding-right,
    "tablet-padding-left": 16px,
    "tablet-padding-right": 16px,
  ),
  "menu": (
    "background": $surface-default,
    "border-color": $accent-border,
    "border-radius": $border-radius-m,
    "box-shadow": $dropdown-shadow,
    "item-color": $text-primary,
    "item-hover-color": $surface-alt,
  ),
  "indicator": (
    "border-radius": $border-radius-xs $border-radius-xs 0 0,
  ),
);

@mixin theming {
  @include horizontal-tab-navigation.base($horizontal-tab-navigation-theme);

  .horizontal-tab-navigation__container {
    @include container;
  }
}
