@use "variables" as *;
@use "mixins" as *;

.help {
  $border: 1px solid $info-border-opacity-50;
  $title-top-margin: 16px;
  $title-bottom-margin: 12px;
  $link-margin: 8px;
  $text-margin: 8px;
  $separator-margin: 16px;

  background: $info-tint;
  border: $border;
  border-radius: $border-radius-l;
  padding: 24px;

  &__title {
    border-top: $border;
    color: $text-secondary;
    font-size: 14px;
    font-weight: 700;
    margin: $title-top-margin 0 $title-bottom-margin;
    padding-top: $title-top-margin;
    text-transform: uppercase;

    &:first-child {
      border-top: none;
      margin-top: 0;
      padding-top: 0;
    }

    &_no-bottom-margin {
      margin-bottom: 0;
    }
  }

  &__text {
    color: $text-primary;
    line-height: 20px;
    margin: $text-margin 0 0;

    &::first-letter {
      text-transform: capitalize;
    }
  }

  &__text-link {
    @include all-link-states {
      color: $link-default;
    }

    &:hover {
      color: $link-emphasis;
    }
  }

  &__list {
    margin: $text-margin 0 0;
    padding-left: 25px;

    > li + li {
      margin-top: 7px;
    }
  }

  &__link {
    display: block;
    margin: $link-margin 0 0;
  }

  &__separator {
    border-top: $border;
    margin: $separator-margin 0;
  }

  &__counters {
    color: $text-secondary;
    font-size: 20px;
    font-weight: 700;
  }

  &__counters-active {
    color: $text-primary;
  }
}
