@use "shared/mixins/map-get-strict" as *;
@use "shared/mixins/wrap-with-root" as *;

@mixin base($settings) {
  @include wrap-with-root {
    @include _base-vars($settings);
  }
}

@mixin _base-vars($settings) {
  --lcap-editor-invalid-marker-background: #{map_get_strict($settings, "invalid-marker-background")};
  --lcap-editor-warning-marker-background: #{map_get_strict($settings, "warning-marker-background")};
  --lcap-editor-invalid-tooltip-icon-color: #{map_get_strict($settings, "invalid-tooltip-icon-color")};
  --lcap-editor-warning-tooltip-icon-color: #{map_get_strict($settings, "warning-tooltip-icon-color")};
  --lcap-widget-editor-highlighted-background: #{map_get_strict($settings, "highlighted-background")};
  --lcap-widget-editor-hovered-border: #{map_get_strict($settings, "hovered-border")};
  --lcap-widget-editor-active-background: #{map_get_strict($settings, "active-background")};
  --lcap-widget-editor-active-border: #{map_get_strict($settings, "active-border")};
  --lcap-widget-editor-invalid-background: #{map_get_strict($settings, "invalid-background")};
  --lcap-widget-editor-warning-background: #{map_get_strict($settings, "warning-background")};
  --lcap-widget-editor-destructive-background: #{map_get_strict($settings, "destructive-background")};
  --lcap-widget-editor-destructive-border: #{map_get_strict($settings, "destructive-border")};
  --lcap-widget-editor-resize-handle-background: #{map_get_strict($settings, "resize-handle-background")};
  --lcap-widget-editor-resize-handle-destructive-background: #{map_get_strict(
      $settings,
      "resize-handle-destructive-background"
    )};
  --lcap-widget-editor-dragged-background: #{map_get_strict($settings, "dragged-background")};
  --lcap-widget-editor-dragged-border: #{map_get_strict($settings, "dragged-border")};
}
