@use "variables" as *;

.fields-mapping-preview {
  $map-icon-width: 130px;

  background: $surface-default;
  border: 1px solid $border-subtle;
  border-collapse: collapse;
  width: 100%;

  &__header {
    background: $neutral-tint;
  }

  &__row {
    background: $neutral-tint;

    &_selected {
      background: $surface-default;
    }
  }

  &__cell {
    border-top: 1px solid $border-subtle;
    cursor: default;
    font-weight: 400;
    opacity: 0.5;
    padding: 12px 0;
    text-align: left;
    white-space: nowrap;

    &_checkbox,
    &_map-icon,
    .fields-mapping-preview__header &,
    .fields-mapping-preview__row_selected & {
      opacity: 1;
    }

    &_checkbox {
      padding: 17px 20px;
      width: 1px;
    }

    &_field {
      width: 1px;
    }

    &_map-icon {
      width: $map-icon-width;
    }
  }

  &__checkbox {
    margin: 0;
    visibility: hidden;

    .fields-mapping-preview__header &,
    .fields-mapping-preview__row_matched:not(.fields-mapping-preview__row_complex) & {
      visibility: visible;
    }
  }

  &__app-icon {
    font-size: 0;
    margin-right: 5px;
    vertical-align: -6px;

    &_properties {
      background: $accent-default;
      border-radius: 4px;
      color: $icon-inverse;
      padding: 4px;
      vertical-align: -4px;
    }
  }

  &__map-icon {
    color: $neutral-subtle;
    display: flex;
    justify-content: center;
    width: $map-icon-width;
  }

  &__step-title {
    display: inline-block;

    .recipe-step-title {
      white-space: nowrap;
    }
  }

  &__splitter {
    font-weight: 700;
    margin: 0 3px 0 5px;
  }

  &__nest-icon {
    border-bottom: 1px solid $neutral-subtle;
    border-left: 1px solid $neutral-subtle;
    display: inline-block;
    height: 14px;
    margin: 0 8px 0 9px;
    position: relative;
    top: -3px;
    width: 10px;
  }

  &__data-type-icon {
    fill: $icon-default;
    height: 18px;
    margin-right: 6px;
    vertical-align: -4px;
    width: 18px;
  }

  .pill {
    font-size: 12px;
    line-height: 15px;
  }
}
