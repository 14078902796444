@use "variables" as *;

.chart-abline {
  &__label {
    fill: var(--chart-abline-label-fill, #{$text-secondary});
    font-size: 12px;
    text-anchor: end;

    .chart-abline_vertical & {
      text-anchor: middle;
    }
  }

  &__line {
    stroke: var(--chart-abline-line-stroke, #{$neutral-default});
    stroke-dasharray: 5;
  }

  &_indigo {
    .chart-abline__label {
      fill: $indigo-fg;
    }

    .chart-abline__line {
      stroke: $indigo-border;
    }
  }

  &_blue {
    .chart-abline__label {
      fill: $blue-fg;
    }

    .chart-abline__line {
      stroke: $blue-border;
    }
  }
}
