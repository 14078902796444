@use "variables" as *;
@use "mixins" as *;

.properties-list {
  @include reset-list;

  color: $text-primary;

  &__item {
    @include break-word;

    display: flex;
    gap: 8px;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    @include device(phone) {
      flex-direction: column;
    }
  }

  &__item-label {
    flex: 0 0 auto;
    font-weight: 600;
    width: 160px;

    @include device(phone) {
      width: auto;
    }
  }

  &__item-text {
    text-decoration: none;
  }

  &__item-text-content {
    margin-right: 8px;
  }

  &_equal &__item-label,
  &_equal &__item-text {
    min-width: 0;
    width: 50%;
  }

  &__copy-button {
    margin: 0 8px 0 0;
  }

  &_compact {
    font-size: 14px;
    padding: 0;
  }

  &_compact &__item {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &_compact &__item-label,
  &_compact &__item-text {
    min-width: 120px;
  }

  w-api-activity-switcher {
    margin-top: -3px;
  }
}
