@use "variables" as *;

@mixin card-container {
  background: $card-background;
  border: 1px solid transparent;
  border-radius: $border-radius-l;
  box-shadow: $card-resting-shadow;
  display: block;
  text-decoration: none;
  transition: $primary-hover-transition;

  html:not(.w-dnd__active) & {
    &:hover,
    &.focus-visible:focus,
    &:active {
      background: $card-hover-background;
      border-color: $card-border;
      box-shadow: $card-hover-shadow;
      text-decoration: none;
    }
  }

  .dropdown-menu-trigger__icon {
    fill: $icon-default;

    &:hover {
      fill: $icon-emphasis;
    }
  }
}
