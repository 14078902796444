@use "variables" as *;

.user-select-filter-option {
  align-items: center;
  display: flex;

  &__avatar {
    margin-right: 7px;

    &_dark {
      color: $icon-default;
    }
  }
}
