@use "variables" as *;

.adapter-select {
  &__value {
    padding-left: 40px;
  }

  &__value,
  &__dropdown &__item {
    .app-icon {
      left: 20px;
      margin: 0;
      position: absolute;
      top: 7px;
    }
  }

  &__dropdown &__item {
    position: relative;

    .app-icon {
      top: 10px;
    }

    .app-icon__release-type {
      font-size: 10px;
    }
  }

  &__item.select-dropdown__item {
    border-bottom: 1px solid $border-subtle;
    display: block;
    padding-left: 60px;
  }

  &__restricted-icon {
    color: $orange-default;
    display: inline-block;
    margin-top: -7px;
    position: absolute;
    right: 30px;
    top: 50%;
  }

  &__recommended-header {
    background: $surface-alt;
    color: $text-primary;
    font-size: 12px;
    padding: 10px 15px;
  }
}
