@use "variables" as *;
@use "mixins" as *;
@use "shared/theming-mixins/spinner";

.simple-page {
  background: $backdrop-landing;

  .page-spinner {
    @include spinner.color($icon-inverse);
  }

  .w-main-header,
  .footer {
    display: none;
  }

  .w-page-main {
    padding-top: 0;
  }
}
