@use "variables" as *;

.bar-chart-tooltip {
  background: $surface-inverse;
  border-radius: 2px;
  color: $text-inverse;
  font-family: $primary-font-family;
  font-size: 12px;
  left: 0;
  padding: 6px 8px;
  pointer-events: none;
  position: absolute;
  top: 0;
  z-index: 100;
}
