@use "variables" as *;

.chart {
  height: 100%;
  position: relative;

  svg {
    overflow: visible;
  }

  &__content {
    height: 100%;
  }

  &_barchart {
    .chart-axis .domain {
      color: $border-subtle;
    }
  }
}
