@use "variables" as *;
@use "mixins" as *;

.w-page-global {
  background-color: $backdrop-default;

  &_overflow-hidden {
    overflow: hidden;
  }

  &_sidebar-opened {
    @include device(desktop-small) {
      overflow: hidden;
    }
  }

  &_pushed {
    @include device(compact) {
      overflow: hidden;
    }
  }
}
