@use "variables" as *;
@use "mixins" as *;

.settings {
  background: $surface-default;
  border: 1px solid $border-subtle;
  border-radius: $border-radius-l;
  box-shadow: $card-resting-shadow;
  display: block;
  padding: 44px;

  @include device(phablet) {
    padding: 24px;
  }

  & + & {
    margin-top: 24px;
  }

  &_margin-bottom {
    margin-bottom: 24px;
  }

  &_empty {
    display: block;
    padding: 0;
  }

  &__header {
    border-bottom: 1px solid $border-subtle;
    margin-bottom: 24px;
    padding-bottom: 8px;
  }

  &__header-group {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  &__title {
    @include break-word;

    align-items: center;
    display: flex;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    margin: 0;

    &_large {
      font-size: 20px;
    }

    &_small {
      font-size: 14px;
    }

    .settings__title-tags_type_env {
      display: flex;
    }
  }

  &__title-aside {
    margin-left: auto;
  }

  &__title-tags {
    margin-left: 8px;
  }

  &__title-indicator {
    display: inline-block;
    margin-right: 8px;
  }

  &__sub-title {
    margin: 4px 0 8px;
  }

  &__content {
    & + & {
      margin-top: 44px;
    }
  }

  &__form,
  &__field {
    max-width: 492px;
  }

  &__form_full {
    max-width: none;
  }

  &__help {
    margin-bottom: $form-field-spacing;
  }

  &__notification {
    display: block;
    margin-bottom: 24px;

    &_bottom {
      margin-bottom: 0;
      margin-top: 16px;
    }
  }

  &__text {
    margin-bottom: 16px;
  }

  &__footer {
    margin-top: 24px;

    &_margin_small {
      margin-top: 16px;
    }
  }

  &__separator {
    border-bottom: 1px solid $border-subtle;
    margin: 24px 0;
  }

  &__collapsible-block {
    margin-bottom: 24px;
  }
}
