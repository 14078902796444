@use "theme" as *;

$elevation-1:
  0 3px 3px $neutral-dark-opacity-3,
  0 8px 12px -14px $neutral-dark-opacity-8;
/* stylelint-disable scss/dollar-variable-pattern -- These color names are reused from Figma */
$elevation-4_neutral:
  0 8px 8px $neutral-dark-opacity-3,
  0 12px 10px -12px $neutral-opacity-15;
$elevation-4_teal:
  0 8px 8px $neutral-dark-opacity-3,
  0 12px 10px -12px $accent-muted-opacity-15;
$elevation-4_red:
  0 8px 8px $neutral-dark-opacity-3,
  0 12px 10px -12px $error-muted-opacity-15;
$elevation-4_indigo:
  0 8px 8px $neutral-dark-opacity-3,
  0 12px 10px -12px $indigo-opacity-15;
/* stylelint-enable scss/dollar-variable-pattern */
$elevation-8:
  0 12px 12px $neutral-dark-opacity-3,
  0 4px 12px -2px $neutral-dark-opacity-8;
$elevation-12:
  0 14px 14px $neutral-dark-opacity-3,
  0 8px 16px $neutral-dark-opacity-8;
$elevation-16:
  0 20px 20px $neutral-dark-opacity-3,
  0 12px 16px $neutral-dark-opacity-8;

$active-shadow: $elevation-1;
$button-shadow: $elevation-1;
$banner-shadow: $elevation-1;
$action-bar-shadow: $elevation-1;
$header-shadow:
  0 1px $border-subtle,
  $elevation-1;
$toast-shadow: $elevation-8;
$dragged-shadow: $elevation-8;
$tooltip-shadow: $elevation-8;
$dropdown-shadow: $elevation-8;
$bubble-shadow: $elevation-8;
$sidebar-shadow: $elevation-8;
$sidebar-submenu-shadow: $elevation-12;
$popup-shadow: $elevation-16;
/* stylelint-disable color-no-hex -- This color should not change with the theme */
$drop-zone-shadow:
  inset 2px 2px 6px $neutral-dark-opacity-20,
  inset -1px -1px 1px #fff;
/* stylelint-enable color-no-hex */
$outline-shadow:
  0 -1px 1px $blue-border-emphasis,
  0 1px 1px $blue-border-emphasis,
  0 -1.5px 0.5px $neutral-border,
  0 1.5px 0.5px $neutral-border;
