@use "variables" as *;
@use "mixins" as *;

.dashboard {
  &__main {
    background: $surface-default;
    border: 1px solid $border-subtle;
    border-radius: $border-radius-l;
    box-shadow: $card-resting-shadow;
  }

  &__content {
    overflow: hidden;
  }

  &__content_loading {
    @include loading;
  }

  &__chart,
  &__counters {
    box-shadow: $header-shadow;
  }

  &__counters {
    background: $surface-elevated;
    border-top-left-radius: $border-radius-l;
    border-top-right-radius: $border-radius-l;
    padding: 18px 42px 24px;
  }

  &__chart {
    padding: 32px 0 16px;
  }

  &__chart-timezone {
    color: $text-secondary;
    font-size: 10px;
    padding: 5px 50px 0;
    text-align: right;
  }

  &__last-updated {
    color: $text-secondary;
    padding: 6px 0;
  }
}
