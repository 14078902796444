@use "variables" as *;

.manifest-tree-item-content-extra-checkbox {
  align-items: center;
  align-self: center;
  color: $text-secondary;
  display: flex;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 0;

  &__input[type="checkbox"] {
    height: 12px;
    margin: 0 4px 0 0;
    width: 12px;
  }
}
