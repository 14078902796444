@use "variables" as *;

.chart-popover-tag {
  align-items: center;
  border: 1px solid transparent;
  border-radius: $border-radius-s;
  display: inline-flex;
  font-size: 12px;
  font-weight: 600;
  justify-content: center;
  line-height: 16px;
  margin-right: 2px;
  padding: 0 3px;
  text-transform: uppercase;
}
