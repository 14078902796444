@use "variables" as *;
@use "mixins" as *;

.dashboard-counter {
  flex: 1;
  text-align: center;

  @include device(phablet) {
    flex: auto;
    width: 50%;
  }

  &_default {
    @include device(phablet) {
      margin-bottom: 24px;
    }
  }

  &_failed {
    flex-grow: 1.5;

    @include device(phablet) {
      flex-grow: 1;
    }
  }

  &_success {
    flex-grow: 1.5;

    @include device(phablet) {
      margin-bottom: 24px;
    }
  }

  &_connections {
    flex-basis: 0;
    flex-grow: 1;
  }

  &__number {
    font-size: 36px;
    font-weight: 600;
    margin: 0 0 2px;
  }

  &_success &__number {
    color: $green-fg;
  }

  &_failed &__number {
    color: $red-fg;
  }

  &_connections &__number {
    color: $blue-fg;
  }

  &_none &__number {
    color: $text-secondary;
  }

  &_empty &__number {
    color: $neutral-subtle;
  }

  &_neutral &__number {
    color: $text-secondary;
  }

  &__description {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 4px;

    &_secondary {
      color: $text-secondary;
      display: block;
      font-size: 10px;
      text-align: center;
      white-space: nowrap;

      @include device(phablet) {
        white-space: normal;
      }
    }
  }

  &__description-text {
    white-space: nowrap;
  }

  &__help-icon {
    color: $neutral-muted;
    margin-left: 4px;
  }

  &__icon {
    border-radius: 2px;
    display: inline-block;
    height: 9px;
    margin: 0 5px 0 0;
    width: 9px;
  }

  &_success &__icon {
    background-color: $success-muted;
  }

  &_failed &__icon {
    background-color: $error-muted;
  }

  &_none &__icon {
    opacity: 0.4;
  }
}
