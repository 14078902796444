/*
 * This is a manifest file that'll be compiled into application.css, which will include all the files
 * listed below.
 *
 * Any CSS and SCSS file within this directory, lib/assets/stylesheets, vendor/assets/stylesheets,
 * or vendor/assets/stylesheets of plugins, if any, can be referenced here using a relative path.
 *
 * You're free to add application-wide styles to this file and they'll appear at the top of the
 * compiled file, but it's generally better to create a new file per style scope.
 *
 */

/* Theming goes first to allow easier override in global styles */
@use "theming" as *;
@use "variables";
@use "animations";
@use "mixins";
@use "app.common";
@use "components";
@use "adapters";
@use "shared/vendor_overrides/angular-cdk";

@include app-shared-theming;
