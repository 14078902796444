@use "./theme" as *;

@mixin background-image-theme(
  $file-base-name,
  $file-extension: "svg",
  $has-oem-override: false,
  $shared: false,
  // $drop-legacy-local-theme-support disables the legacy local theme support.
  // You may want to use it in a following scenario, when in safari <17.4
  // and firefox background-image-theme fails without it:
  // (below background-image-theme is applied to .myDiv)
  //
  // <div wLocalTheme="light">
  //  <div wLocalTheme="dark"></div>
  //  <div class="myDiv"></div>
  // </div>
  $drop-legacy-local-theme-support: false
) {
  @include theme(light) {
    background-image: get-image-url($shared, get-file-path("light", $file-base-name, $file-extension));
  }

  @include theme(dark) {
    background-image: get-image-url($shared, get-file-path("dark", $file-base-name, $file-extension));
  }

  @if $has-oem-override {
    @include oem-theme(light) {
      background-image: get-image-url($shared, get-file-path("oem_light", $file-base-name, $file-extension));
    }

    @include oem-theme(dark) {
      background-image: get-image-url($shared, get-file-path("oem_dark", $file-base-name, $file-extension));
    }
  }

  @if $drop-legacy-local-theme-support {
    @at-root html:not(.supports-scoped-css) .light-theme & {
      background-image: get-image-url($shared, get-file-path("light", $file-base-name, $file-extension));
    }

    @at-root html:not(.supports-scoped-css) .dark-theme & {
      background-image: get-image-url($shared, get-file-path("dark", $file-base-name, $file-extension));
    }
  }
}

@function get-image-url($shared, $path) {
  @if $shared {
    @return shared-image-url($path);
  } @else {
    @return image-url($path);
  }
}

@function get-file-path($theme, $file-base-name, $file-extension) {
  @return $file-base-name + "_" + $theme + "." + $file-extension;
}
