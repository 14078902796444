@use "shared/mixins/map-get-strict" as *;

@mixin base($settings) {
  .button-icon {
    @include _sizes(map_get_strict($settings, "sizes"));
    @include _themes(map_get_strict($settings, "themes"));
  }
}

@mixin _sizes($sizes-definitions) {
  @each $name, $definitions in $sizes-definitions {
    &_#{$name} {
      --button-icon-size: #{map_get_strict($definitions, "size")};
      --button-icon-border-radius: #{map_get_strict($definitions, "border-radius")};
    }
  }
}

@mixin _themes($themes) {
  @if $themes {
    @each $theme-name, $theme in $themes {
      &_#{$theme-name} {
        --button-icon-color: #{map_get_strict($theme, "color")};
        --button-icon-hover-background: #{map_get_strict($theme, "hover-background")};
        --button-icon-hover-color: #{map_get_strict($theme, "hover-color")};
        --button-icon-active-background: #{map_get_strict($theme, "active-background")};
        --button-icon-active-color: #{map_get_strict($theme, "active-color")};
        --button-icon-disabled-color: #{map_get_strict($theme, "disabled-color")};
      }
    }
  }
}
