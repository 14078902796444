@use "variables" as *;

.drag-separator {
  background: $orange-default;
  height: 2px;
  margin-top: -2px;
  position: absolute;
  right: 0;

  &::before,
  &::after {
    background: $orange-default;
    border-radius: 2px;
    content: "";
    height: 14px;
    left: -2px;
    position: absolute;
    top: -6px;
    width: 2px;
  }

  &::before {
    left: auto;
    right: 0;
  }
}

.drag-separator_light {
  height: 1px;
  margin-top: -1px;

  &::before,
  &::after {
    border-radius: 1px;
    height: 11px;
    left: -1px;
    top: -5px;
    width: 1px;
  }

  &::before {
    left: auto;
    right: 0;
  }
}
