@use "theme" as *;
@use "shadows" as *;
@use "misc" as *;
@use "theme-agnostic" as *;

/* Shared `w-table` component uses all of these variables.
   This file is forwarded in `variables/shared-components/table`. */

$table-text-primary: $text-primary;
$table-icon-default: $icon-default;
$table-icon-emphasis: $icon-emphasis;

$table-header-background: $surface-alt;
$table-row-background: $surface-field;
$table-row-hover-background: $neutral-tint;
$table-card-background: $surface-default;
$table-card-hover-background: $surface-elevated;
$table-row-highlight-background: $surface-inverse-opacity-5;

$table-row-selected-background: $accent-tint;
$table-checkbox-selected-background: $accent-subtle-opacity-50;
$table-checkbox-selected-hover-background: $accent-subtle;

$table-border: $border-subtle;
$table-cell-border: $border-default;

$table-selected-border: $accent-border;
$table-selected-hover-border: $accent-border-emphasis;

$table-row-muted-background: transparent;
$table-muted-cell-border: $border-subtle;

$table-card-shadow: $elevation-1;
$table-card-hover-shadow: $elevation-4_neutral;
$table-sticky-box-shadow: 4px 0 4px 0 $neutral-dark-opacity-8;

$table-cell-padding-horizontal: 12px;
$table-cell-padding-vertical: 10px;

$editable-table-hover-background: $neutral-tint;
$editable-table-config-background: $surface-subtle;
$editable-table-config-hover-background: $surface-alt;

$editable-table-error-background: $error-tint;
$editable-table-error-config-background: $error-subtle;

$editable-table-edit-background: $accent-subtle-opacity-50;
$editable-table-edit-config-background: $accent-subtle;

$editable-text-border: $border-subtle;
$editable-text-hover-border: $border-default;
$editable-text-active-border: $accent-border-emphasis;
$editable-text-placeholder-color: $text-placeholder;

$table-border-radius: $border-radius-m;
$table-checkbox-label-border-radius: $border-radius-s;
$table-dropdown-menu-border-radius: $border-radius-s;

$table-easing-function: $w-easy-ease;

$table-row-muted-stripe-color: $backdrop-default;
$table-row-muted-stripe-background: $surface-default;

$table-scrollbar-color: $scrollbar-color;
$table-scrollbar-hover-color: $scrollbar-hover-color;
